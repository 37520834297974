import { ImageData, FestivalData } from '../model/FestivalData';
import { uploadImage, imageUrl } from './api';

export class ImageService {
  constructor(
    private sessionToken: string,
    private festivalId: string
  ) {}

  async uploadImageData(imageData: ImageData | null): Promise<ImageData | null> {
    console.log("uploadImageData called with:", imageData);
    if (imageData && imageData.type === 'ApiImage' && imageData.file) {
      console.log("Uploading image file:", imageData.file);
      const identifier = await uploadImage(imageData.file, this.sessionToken, this.festivalId);
      console.log("Got identifier:", identifier);
      return { 
        type: 'ApiImage', 
        identifier,
        ...(imageData.blurhash ? { blurhash: imageData.blurhash } : {})
      };
    } 
    return imageData;
  }

  async uploadAllImages(data: FestivalData): Promise<FestivalData> {
    console.log("Starting uploadImages");
    const newData = { ...data };

    // Upload festival logo
    if (newData.infos.festival_logo) {
      console.log("Processing festival logo:", newData.infos.festival_logo);
      newData.infos.festival_logo = await this.uploadImageData(newData.infos.festival_logo);
      console.log("Updated festival logo:", newData.infos.festival_logo);
    }

    // Upload theme background image if it exists and type is 'image'
    if (newData.infos.stylesheet.background.image) {
      console.log("Processing theme background image:", newData.infos.stylesheet.background.image);
      newData.infos.stylesheet.background.image = await this.uploadImageData(newData.infos.stylesheet.background.image);
      console.log("Updated theme background image:", newData.infos.stylesheet.background.image);
    }

    // Upload maps image
    for (let i = 0; i < newData.maps.length; i++) {
      newData.maps[i].image = await this.uploadImageData(newData.maps[i].image);
    }

    // Upload images for tickets
    if (newData.infos.tickets) {
      newData.infos.tickets.image = await this.uploadImageData(newData.infos.tickets.image);
    }

    // Upload images for news
    newData.infos.news_header_image = await this.uploadImageData(newData.infos.news_header_image);

    // Upload images for artists
    for (const artistId in newData.artists) {
      newData.artists[artistId].main_image = await this.uploadImageData(newData.artists[artistId].main_image) as ImageData;
    }

    // Upload images for POIs
    for (const poiId in newData.pois) {
      newData.pois[poiId].icon = await this.uploadImageData(newData.pois[poiId].icon);
      newData.pois[poiId].main_image = await this.uploadImageData(newData.pois[poiId].main_image);
    }

    console.log("… images uploaded");
    return newData;
  }

  getImageUrl(identifier: string): string {
    return imageUrl(identifier, this.festivalId);
  }
}

export const getImageUrl = (image: ImageData | null, getApiImageUrl: (identifier: string) => string): string | undefined => {
  if (!image) return undefined;

  if (image.type === 'ApiImage') {
    if ('file' in image && image.file) {
      return URL.createObjectURL(image.file);
    }
    return getApiImageUrl(image.identifier);
  }
  return image.url;
}; 