import React from 'react';

interface ImageEditorsGroupProps {
  children: React.ReactNode;
}

const ImageEditorsGroup: React.FC<ImageEditorsGroupProps> = ({ children }) => (
  <div className="theme-section-group">
    <div className="image-editors-grid">
      {children}
    </div>
  </div>
);

export default ImageEditorsGroup; 