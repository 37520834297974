import React, { useState, useEffect, useRef } from 'react';
import { encode } from 'blurhash';
import { ImageData } from '../../../../model/FestivalData';
import { getImageUrl } from '../../../../services/imageService';
import './ImageEditor.css';

interface ImageEditorProps {
  currentImage?: ImageData;
  onChange: (newImage: ImageData | undefined) => void;
  getApiImageUrl: (identifier: string) => string;
  tempPreviewUrl?: string;
  fillMode?: 'cover' | 'contain';
  title?: string;
  caption?: string;
}

const ImageEditor: React.FC<ImageEditorProps> = ({ currentImage, onChange, getApiImageUrl, tempPreviewUrl, fillMode = 'cover', title, caption }) => {
  const [imageType, setImageType] = useState<'ApiImage' | 'ExternalLink'>(
    currentImage?.type || 'ApiImage'
  );
  const [file, setFile] = useState<File | null>(null);
  const [externalUrl, setExternalUrl] = useState<string>('');
  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (currentImage) {
      if (currentImage.type === 'ApiImage') {
        if ('file' in currentImage && currentImage.file) {
          setFile(currentImage.file);
          setPreviewUrl(URL.createObjectURL(currentImage.file));
        } else if (currentImage.identifier) {
          const url = getImageUrl(currentImage, getApiImageUrl);
          setPreviewUrl(url || null);
        }
        setExternalUrl('');
      } else {
        setExternalUrl(currentImage.url);
        setPreviewUrl(currentImage.url);
        setFile(null);
      }
    } else {
      setFile(null);
      setExternalUrl('');
      setPreviewUrl(tempPreviewUrl || null);
    }

    return () => {
      if (previewUrl && previewUrl.startsWith('blob:')) {
        URL.revokeObjectURL(previewUrl);
      }
    };
  }, [currentImage, getApiImageUrl, tempPreviewUrl]);

  const handleTypeChange = (newType: 'ApiImage' | 'ExternalLink') => {
    console.log('handleTypeChange called with:', newType);
    
    // Only make changes if type is actually changing
    if (newType !== imageType) {
      setImageType(newType);
      setError(null);
      
      if (newType === 'ApiImage') {
        setExternalUrl('');
        setPreviewUrl(null);
        setFile(null);
        onChange(undefined);
      } else {
        setFile(null);
        setPreviewUrl(null);
        setExternalUrl('');
        onChange(undefined);
      }
    }
  };

  const validateUrl = (url: string): boolean => {
    try {
      new URL(url);
      return true;
    } catch {
      return false;
    }
  };

  const loadImage = (src: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  };

  const getImageData = (image: HTMLImageElement): Uint8ClampedArray => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d')!;
    canvas.width = 32;  // small size for blurhash
    canvas.height = Math.floor((image.height * 32) / image.width);
    
    context.drawImage(image, 0, 0, canvas.width, canvas.height);
    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    return imageData.data;
  };

  const computeBlurhash = async (imageUrl: string): Promise<string | undefined> => {
    try {
      const img = await loadImage(imageUrl);
      const pixels = getImageData(img);
      
      const blurhash = encode(
        pixels,
        32, // width
        Math.floor((img.height * 32) / img.width), // height
        4,  // componentX
        3   // componentY
      );
      
      return blurhash;
    } catch (error) {
      console.error('Error computing blurhash:', error);
      return undefined;
    }
  };

  const handleUrlChange = async (value: string) => {
    setExternalUrl(value);
    if (value && !validateUrl(value)) {
      setError('Please enter a valid URL');
      setPreviewUrl(null);
    } else {
      setError(null);
      setPreviewUrl(value);
      
      if (value) {
        const blurhash = await computeBlurhash(value);
        onChange({ 
          type: 'ExternalLink', 
          url: value,
          ...(blurhash ? { blurhash } : {})
        });
      } else {
        onChange(undefined);
      }
    }
  };

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      console.log('ImageEditor - handleFileChange - Selected file:', selectedFile);
      setFile(selectedFile);
      const localPreviewUrl = URL.createObjectURL(selectedFile);
      setPreviewUrl(localPreviewUrl);
      
      const blurhash = await computeBlurhash(localPreviewUrl);
      const newImageData: ImageData = { 
        type: 'ApiImage', 
        file: selectedFile, 
        identifier: '',
        ...(blurhash ? { blurhash } : {})
      };
      
      console.log('ImageEditor - handleFileChange - Calling onChange with:', newImageData);
      onChange(newImageData);
    } else {
      setFile(null);
      setPreviewUrl(null);
      onChange(undefined);
    }
    setExternalUrl('');
  };

  const handleBrowseClick = () => {
    fileInputRef.current?.click();
  };

  const ImagePlaceholder = () => (
    <div className="image-placeholder">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor">
        <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
        <circle cx="8.5" cy="8.5" r="1.5" />
        <polyline points="21 15 16 10 5 21" />
      </svg>
      <p>No image selected</p>
    </div>
  );

  return (
    <div className="image-editor">
      {title && <div className="image-title">{title}</div>}
      <div className="image-preview">
      {isLoading && (
          <div className="loading-overlay">
            <div className="loading-spinner" />
          </div>
        )}
        <div className="editor-controls">
          <div className="image-type-toggle">
            <button
              className={imageType === 'ApiImage' ? 'active' : ''}
              onClick={() => handleTypeChange('ApiImage')}
            >
              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" className="icon">
                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                <polyline points="17 8 12 3 7 8" />
                <line x1="12" y1="3" x2="12" y2="15" />
              </svg>
              Upload
            </button>
            <button
              className={imageType === 'ExternalLink' ? 'active' : ''}
              onClick={() => handleTypeChange('ExternalLink')}
            >
              <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" className="icon">
                <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" />
                <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" />
              </svg>
              Link
            </button>
          </div>
          {imageType === 'ApiImage' ? (
            <button className="browse-button" onClick={handleBrowseClick}>
              Choose Image
            </button>
          ) : (
            <input
              type="text"
              value={externalUrl}
              onChange={(e) => handleUrlChange(e.target.value)}
              placeholder="Enter image URL"
              className="url-input"
            />
          )}
        </div>
        {previewUrl ? (
          <div className="image-container">
            <img 
              src={previewUrl} 
              alt="Preview" 
              className={`image-${fillMode}`}
              onLoadStart= { () => { setIsLoading(true) }}
              onLoad={() => setIsLoading(false)}
              onError={() => {
                setIsLoading(false);
                setError('Failed to load image');
              }}
            />
          </div>
        ) : (
          <ImagePlaceholder />
        )}
      </div>

      {imageType === 'ApiImage' && (
        <input
          type="file"
          ref={fileInputRef}
          onLoadStart= { () => { setIsLoading(true) }}
          onChange={handleFileChange}
          style={{ display: 'none' }}
          accept="image/*"
        />
      )}
      
      {caption && <div className="image-caption">{caption}</div>}
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default ImageEditor;
