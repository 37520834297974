import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, Link, useLocation, useHistory } from 'react-router-dom';
import './Editor.css';
import { DataDiff, FestivalData, PUBLISHED_STATUS } from '../../model/FestivalData';
import InfosEditor from './components/InfosEditor/InfosEditor';
import NewsEditor from './components/NewsEditor/NewsEditor';
import ArtistsEditor from './components/ArtistsEditor/ArtistsEditor';
import PoisEditor from './components/PoisEditor/PoisEditor';
import ShowsEditor from './components/ShowsEditor/ShowsEditor';
import MapsEditor from './components/MapsEditor/MapsEditor';
import TicketsEditor from './components/TicketsEditor/TicketsEditor';
import { 
  updateFestivalData, 
  uploadImage, 
  imageUrl, 
  fetchCurrentUser, 
} from '../../services/api';
import ConflictModal from '../../components/ConflictModal';
import { calculateDiff } from '../../utils/diffCalculator';
import { fetchCurrentVersion } from '../../services/api';
import VersionHistory from './components/VersionHistory/VersionHistory';
import NotificationsEditor from './components/NotificationsEditor/NotificationsEditor';
import UserEditor from '../Admin/UserEditor/UserEditor';
import Cookies from 'js-cookie';
import { Role, EditorType, getAccessibleEditors } from '../../model/Roles';
import { User } from '../../model/User';
import EditorMenu from './components/EditorMenu/EditorMenu';
import { FestivalDataService } from '../../services/festivalDataService';
import CarpoolsEditor from './components/CarpoolsEditor/CarpoolsEditor';
import ReportingEditor from './components/ReportingEditor/ReportingEditor';
import DeferrableSavingComponent from './components/DeferrableSavingComponent/DeferrableSavingComponent';
import PagesEditor from './components/PagesEditor/PagesEditor';
import AdvertisementsEditor from './components/AdvertisementsEditor/AdvertisementsEditor';

interface EditorProps {
  sessionToken: string;
  festivalId: string;
  currentUser: User;
  defaultTab: EditorType;
}

interface LocationState {
  versionData: FestivalData;
}

const Editor: React.FC<EditorProps> = ({ sessionToken, festivalId, currentUser, defaultTab }) => {
  const { versionId } = useParams<{ versionId: string }>();
  const location = useLocation<LocationState>();
  const history = useHistory();
  
  const [isLoading, setIsLoading] = useState(true);
  const [festivalData, setFestivalData] = useState<FestivalData | null>(location.state?.versionData || null);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [activeEditor, setActiveEditor] = useState<EditorType>(defaultTab);
  const [unsavedChanges, setUnsavedChanges] = useState<Set<string>>(new Set());
  const originalDataRef = useRef<FestivalData | null>(null);
  const [lastSaveTime, setLastSaveTime] = useState<Date | null>(null);
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);
  const [isPublishPromptOpen, setIsPublishPromptOpen] = useState(false);
  const [showConflictModal, setShowConflictModal] = useState(false);
  const [conflictDiffs, setConflictDiffs] = useState<DataDiff[]>([]);
  const [pendingChanges, setPendingChanges] = useState<FestivalData | null>(null);
  const [allowedEditors, setAllowedEditors] = useState<EditorType[]>([]);
  const [currentUserData, setCurrentUserData] = useState<User>(currentUser);
  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const [pendingNavigation, setPendingNavigation] = useState<(() => void) | null>(null);
  const festivalDataService = useRef(new FestivalDataService(sessionToken, festivalId));

  const getApiImageUrl = useCallback((identifier: string) => {
    return festivalDataService.current.getImageUrl(identifier);
  }, []);

  const findChangedProperties = (originalObj: any, newObj: any, prefix = ''): string[] => {
    const changedProps: string[] = [];

    if (originalObj === null || newObj === null || typeof originalObj !== 'object' || typeof newObj !== 'object') {
      if (originalObj !== newObj) {
        changedProps.push(prefix);
      }
      return changedProps;
    }

    const allKeys = Array.from(new Set([...Object.keys(originalObj), ...Object.keys(newObj)]));

    for (const key of allKeys) {
      const newPrefix = prefix ? `${prefix}.${key}` : key;
      
      if (!(key in originalObj)) {
        changedProps.push(newPrefix);
      } else if (!(key in newObj)) {
        changedProps.push(newPrefix);
      } else if (Array.isArray(newObj[key])) {
        if (JSON.stringify(originalObj[key]) !== JSON.stringify(newObj[key])) {
          changedProps.push(newPrefix);
        }
      } else if (typeof newObj[key] === 'object' && newObj[key] !== null) {
        changedProps.push(...findChangedProperties(originalObj[key], newObj[key], newPrefix));
      } else if (originalObj[key] !== newObj[key]) {
        changedProps.push(newPrefix);
      }
    }

    return changedProps;
  };

  const handleDataChange = useCallback((key: keyof FestivalData, newData: any, changedFields?: string[]) => {
    setFestivalData(prevData => {
      if (!prevData) return null;
      return {
        ...prevData,
        [key]: newData
      } as FestivalData;
    });

    const originalData = originalDataRef.current;
    if (originalData) {
      if (changedFields) {
        setUnsavedChanges(prev => {
          const newSet = new Set(prev);
          changedFields.forEach(field => newSet.add(`${key}.${field}`));
          return newSet;
        });
      } else {
        const changedProperties = findChangedProperties(originalData[key], newData);
        setUnsavedChanges(prev => {
          const newSet = new Set(prev);
          changedProperties.forEach(prop => newSet.add(`${key}.${prop}`));
          return newSet;
        });
      }
    }
  }, []);

  useEffect(() => {
    const loadVersionData = async () => {
      if (!festivalData && versionId) {
        try {
          setIsLoading(true);
          const currentVersion = await festivalDataService.current.loadVersion(versionId);
          setFestivalData(currentVersion);
        } catch (error) {
          console.error('Error loading version:', error);
          setError('Failed to load version data');
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    };

    loadVersionData();
  }, [versionId, festivalData]);

  useEffect(() => {
    if (festivalData) {
      originalDataRef.current = festivalData;
    }
  }, [festivalData]);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (unsavedChanges.size > 0) {
        setShowUnsavedModal(true);
        setPendingNavigation(() => () => {
          window.location.reload();
        });
        
        e.preventDefault();
        e.returnValue = '';
        
        return '';
      }
    };

    const handleUnload = (e: Event) => {
      if (unsavedChanges.size > 0) {
        e.preventDefault();
        return '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('unload', handleUnload);
    
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('unload', handleUnload);
    };
  }, [unsavedChanges]);

  useEffect(() => {
    const updateUserData = async () => {
      try {
        const updatedUser = await fetchCurrentUser(sessionToken, festivalId);
        const currentSession = JSON.parse(Cookies.get('session') || '{}');
        const updatedSession = {
          ...currentSession,
          user: updatedUser
        };
        Cookies.set('session', JSON.stringify(updatedSession), { expires: 60 });
        setCurrentUserData(updatedUser);
      } catch (error) {
        console.error('Failed to update user data:', error);
      }
    };

    updateUserData();
  }, [sessionToken, festivalId]);

  useEffect(() => {
    const accessibleEditors = getAccessibleEditors(currentUserData.roles);
    setAllowedEditors(accessibleEditors);
    
    if (!accessibleEditors.includes(activeEditor)) {
      setActiveEditor(accessibleEditors[0]);
    }
  }, [currentUserData.roles, activeEditor]);

  useEffect(() => {
    const handleTabSwitch = (event: Event) => {
      const customEvent = event as CustomEvent;
      if (customEvent.detail === 'map') {
        setActiveEditor('map');
      }
    };

    window.addEventListener('switchTab', handleTabSwitch);
    return () => {
      window.removeEventListener('switchTab', handleTabSwitch);
    };
  }, []);

  useEffect(() => {
    if (unsavedChanges.size > 0) {
      const unblock = history.block((tx) => {
        setShowUnsavedModal(true);
        setPendingNavigation(() => () => {
          unblock();
          history.push(tx.pathname);
        });
        return false;
      });
      return unblock;
    }
  }, [unsavedChanges, history]);

  if (isLoading) {
    return (
      <div className="editor">
        <div className="loading">Loading...</div>
      </div>
    );
  }

  if (!festivalData) {
    return (
      <div className="editor">
        <div className="error">Failed to load version data</div>
        <Link to="/" className="back-btn">
          ← Back to Versions
        </Link>
      </div>
    );
  }

  const handleDataUpdate = (newData: FestivalData) => {
    setFestivalData(newData);
    
    // Calculate changes between original and reverted data
    if (originalDataRef.current) {
      const changedProperties = findChangedProperties(originalDataRef.current, newData);
      setUnsavedChanges(new Set(changedProperties));
    }
    
    // Don't update originalDataRef here, as we want to track changes from the current server state
    setLastSaveTime(null); // Clear last save time since we have pending changes
  };

  const renderActiveEditor = () => {
    switch (activeEditor) {
      case 'infos':
        return (
          <DeferrableSavingComponent
            unsavedChanges={unsavedChanges}
            lastSaveTime={lastSaveTime}
            isSaving={isSaving}
            onSave={handleSave}
            status={festivalData.status}
            onStatusChange={handleStatusChange}
          >
            <InfosEditor 
              infos={festivalData.infos} 
              onChange={(newInfos) => handleDataChange('infos', newInfos)}
              getApiImageUrl={getApiImageUrl}
            />
          </DeferrableSavingComponent>
        );
      case 'map':
        return (
          <DeferrableSavingComponent
            unsavedChanges={unsavedChanges}
            lastSaveTime={lastSaveTime}
            isSaving={isSaving}
            onSave={handleSave}
            status={festivalData.status}
            onStatusChange={handleStatusChange}
          >
            <MapsEditor 
              maps={festivalData.maps}
              onChange={(newMaps) => handleDataChange('maps', newMaps)}
              getApiImageUrl={getApiImageUrl}
            />
          </DeferrableSavingComponent>
        );
      case 'tickets':
        return (
          <DeferrableSavingComponent
            unsavedChanges={unsavedChanges}
            lastSaveTime={lastSaveTime}
            isSaving={isSaving}
            onSave={handleSave}
            status={festivalData.status}
            onStatusChange={handleStatusChange}
          >
            <TicketsEditor 
              tickets={festivalData.infos.tickets} 
              onChange={(newTickets) => handleDataChange('infos', { ...festivalData.infos, tickets: newTickets })}
              getApiImageUrl={getApiImageUrl}
            />
          </DeferrableSavingComponent>
        );
      case 'news':
        return (
            <NewsEditor
              sessionToken={sessionToken}
              festivalId={festivalId}
              getApiImageUrl={getApiImageUrl}
            />
        );
      case 'artists':
        return (
          <DeferrableSavingComponent
            unsavedChanges={unsavedChanges}
            lastSaveTime={lastSaveTime}
            isSaving={isSaving}
            onSave={handleSave}
            status={festivalData.status}
            onStatusChange={handleStatusChange}
          >
            <ArtistsEditor 
              artists={festivalData.artists} 
              onChange={(newArtists) => handleDataChange('artists', newArtists)}
              getApiImageUrl={getApiImageUrl}
            />
          </DeferrableSavingComponent>
        );
      case 'pois':
        return (
          <DeferrableSavingComponent
            unsavedChanges={unsavedChanges}
            lastSaveTime={lastSaveTime}
            isSaving={isSaving}
            onSave={handleSave}
            status={festivalData.status}
            onStatusChange={handleStatusChange}
          >
            <PoisEditor 
              pois={festivalData.pois} 
              onChange={(newPois) => handleDataChange('pois', newPois)}
              getApiImageUrl={getApiImageUrl}
              maps={festivalData.maps}
            />
          </DeferrableSavingComponent>
        );
      case 'shows':
        return (
          <DeferrableSavingComponent
            unsavedChanges={unsavedChanges}
            lastSaveTime={lastSaveTime}
            isSaving={isSaving}
            onSave={handleSave}
            status={festivalData.status}
            onStatusChange={handleStatusChange}
          >
            <ShowsEditor
              shows={festivalData.shows}
              artists={festivalData.artists}
              pois={festivalData.pois}
              onChange={(newShows) => handleDataChange('shows', newShows)}
              getApiImageUrl={(identifier) => imageUrl(identifier, festivalId)}
            />
          </DeferrableSavingComponent>
        );
      case 'history':
        return (
          <VersionHistory
            sessionToken={sessionToken}
            festivalId={festivalId}
            versionId={festivalData.version.toString()}
            onDataUpdate={handleDataUpdate}
          />
        );
      case 'notifications':
        return (
          <NotificationsEditor
            shows={festivalData.shows}
            artists={festivalData.artists}
            sessionToken={sessionToken}
            festivalId={festivalId}
          />
        );
      case 'users':
        return (
          <UserEditor
            sessionToken={sessionToken}
            festivalId={festivalId}
            currentUser={currentUserData}
          />
        );
      case 'carpools':
        return (
          <CarpoolsEditor
            sessionToken={sessionToken}
            festivalId={festivalId}
            userRoles={currentUserData.roles}
          />
        );
      case 'reporting':
        return (
          <ReportingEditor
            sessionToken={sessionToken}
            festivalId={festivalId}
          />
        );
      case 'pages':
        return (
          <PagesEditor
            sessionToken={sessionToken}
            festivalId={festivalId}
            getApiImageUrl={getApiImageUrl}
          />
        );
      case 'advertisements':
        return (
          <AdvertisementsEditor
            sessionToken={sessionToken}
            festivalId={festivalId}
            getApiImageUrl={getApiImageUrl}
          />
        );
      default:
        return null;
    }
  };

  const handleStatusChange = (newStatus: PUBLISHED_STATUS) => {
    if (newStatus === 'published' && festivalData.status === 'draft') {
      setIsPublishPromptOpen(true);
    } else {
      setFestivalData(prevData => {
        if (!prevData) return null;
        return {
          ...prevData,
          status: newStatus
        } as FestivalData;
      });
      setUnsavedChanges(prev => new Set(prev).add('status'));
    }
    setShowStatusDropdown(false);
  };

  const handlePublishConfirm = () => {
    setFestivalData(prevData => {
      if (!prevData) return null;
      return {
        ...prevData,
        status: 'published'
      } as FestivalData;
    });
    setUnsavedChanges(prev => new Set(prev).add('status'));
    setIsPublishPromptOpen(false);
  };

  const handleSave = async () => {
    if (!festivalData) return;
    
    try {
      setIsSaving(true);
      setError(null);

      // Check for conflicts
      const conflicts = await festivalDataService.current.checkForConflicts(festivalData);
      if (conflicts) {
        setPendingChanges(festivalData);
        setConflictDiffs(conflicts);
        setShowConflictModal(true);
        return;
      }

      // Save the data
      const { updated_at } = await festivalDataService.current.saveVersion(festivalData);
      
      // Update local state
      const updatedFestivalData = {
        ...festivalData,
        updated_at,
      };
      
      setFestivalData(updatedFestivalData);
      setUnsavedChanges(new Set());
      originalDataRef.current = updatedFestivalData;
      setLastSaveTime(new Date());
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred while saving');
    } finally {
      setIsSaving(false);
    }
  };

  const handleOverwrite = async () => {
    if (!pendingChanges) return;
    try {
      setIsSaving(true);
      await updateFestivalData(sessionToken, festivalId, pendingChanges.version.toString(), pendingChanges);
      setFestivalData(pendingChanges);
      setUnsavedChanges(new Set());
      originalDataRef.current = pendingChanges;
      setLastSaveTime(new Date());
      setShowConflictModal(false);
      setPendingChanges(null);
    } catch (error) {
      console.error('Error overwriting festival data:', error);
      setError('Error saving');
    } finally {
      setIsSaving(false);
    }
  };

  const handleReload = async () => {
    try {
      const currentVersion = await fetchCurrentVersion(sessionToken, festivalId, festivalData!.version.toString());
      setFestivalData(currentVersion);
      setShowConflictModal(false);
      setPendingChanges(null);
      setUnsavedChanges(new Set());
      setLastSaveTime(new Date());
    } catch (error) {
      console.error('Error reloading festival data:', error);
      setError('Error reloading');
    }
  };

  const handleTabChange = (newTab: EditorType) => {
    if (unsavedChanges.size > 0) {
      setShowUnsavedModal(true);
      setPendingNavigation(() => () => {
        setActiveEditor(newTab);
        history.push(`/editor/${versionId}/${newTab}`);
      });
    } else {
      setActiveEditor(newTab);
      history.push(`/editor/${versionId}/${newTab}`);
    }
  };

  const handleDiscardChanges = () => {
    setUnsavedChanges(new Set()); // Clear unsaved changes
    if (pendingNavigation) {
      pendingNavigation();
    }
    setShowUnsavedModal(false);
  };

  const handleSaveAndQuit = async () => {
    try {
      await handleSave();
      if (pendingNavigation) {
        pendingNavigation();
      }
      setShowUnsavedModal(false);
    } catch (error) {
      console.error('Failed to save:', error);
      // You might want to show an error message here
    }
  };

  return (
    <div className="editor">
      <header className="editor-header">
        <div className="header-content">
          <Link to="/" className="back-btn">
            ← Back to Versions
          </Link>
          <h1>{festivalData.infos.festival_name} - Version {festivalData.version}</h1>
        </div>
      </header>
      <div className="editor-layout">
        <EditorMenu 
          allowedEditors={allowedEditors}
          activeEditor={activeEditor}
          onTabChange={handleTabChange}
        />
        <main className="editor-main">
          <div className="editor-content">
            {renderActiveEditor()}
            {error && <div className="error">{error}</div>}
          </div>
        </main>
      </div>
      {isPublishPromptOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Publish Version</h3>
            <p>This version will be published to the App. Are you sure?</p>
            <div className="modal-actions">
              <button onClick={handlePublishConfirm}>Yes, Publish</button>
              <button onClick={() => setIsPublishPromptOpen(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
      {showConflictModal && (
        <ConflictModal
          diffs={conflictDiffs}
          onOverwrite={handleOverwrite}
          onReload={handleReload}
          onClose={() => setShowConflictModal(false)}
        />
      )}
      {showUnsavedModal && (
        <div className="unsaved-modal-overlay">
          <div className="unsaved-modal">
            <h2>Unsaved changes</h2>
            <p>Do you want to save your changes?</p>
            <div className="unsaved-modal-buttons">
              <button 
                className="cancel"
                onClick={() => setShowUnsavedModal(false)}
              >
                Cancel
              </button>
              <button 
                className="discard"
                onClick={handleDiscardChanges}
              >
                Discard changes
              </button>
              <button 
                className="save"
                onClick={handleSaveAndQuit}
                disabled={isSaving}
              >
                {isSaving ? 'Saving...' : 'Save & quit'}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Editor;
