import React, { useEffect, useState } from 'react';
import { Carpool } from '../../../../model/Carpool';
import { fetchCarpools, deleteCarpool } from '../../../../services/api';
import Modal from '../../../../components/Modal';
import './CarpoolsEditor.css';
import { Role } from '../../../../model/Roles';

interface CarpoolsEditorProps {
  sessionToken: string;
  festivalId: string;
  userRoles: string[];
}

interface DeleteConfirmModalProps {
  carpool: Carpool;
  onConfirm: () => Promise<void>;
  onCancel: () => void;
}

const DeleteConfirmModal: React.FC<DeleteConfirmModalProps> = ({ carpool, onConfirm, onCancel }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleDelete = async () => {
    setIsDeleting(true);
    setError(null);
    try {
      await onConfirm();
      onCancel();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to delete carpool');
      setIsDeleting(false);
    }
  };

  return (
    <Modal isOpen={true} onClose={() => !isDeleting && onCancel()}>
      <div className="modal-header">
        <h3>Delete Carpool</h3>
        {!isDeleting && (
          <button className="modal-close" onClick={onCancel}>×</button>
        )}
      </div>
      {error && <div className="modal-error">{error}</div>}
      <p>Are you sure you want to delete this carpool from {carpool.user_name}?</p>
      <div className="modal-actions">
        <button 
          className="modal-delete"
          onClick={handleDelete}
          disabled={isDeleting}
        >
          {isDeleting ? 'Deleting...' : 'Delete'}
        </button>
        <button 
          className="modal-cancel"
          onClick={onCancel}
          disabled={isDeleting}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

const CarpoolsEditor: React.FC<CarpoolsEditorProps> = ({ sessionToken, festivalId, userRoles }) => {
  const [carpools, setCarpools] = useState<Carpool[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [carpoolToDelete, setCarpoolToDelete] = useState<Carpool | null>(null);

  const isViewerOnly = userRoles.includes(Role.CARPOOLS_VIEWER) && 
                      !userRoles.includes(Role.CARPOOLS) && 
                      !userRoles.includes(Role.ADMIN);

  const loadCarpools = async () => {
    try {
      setIsLoading(true);
      const data = await fetchCarpools(sessionToken, festivalId);
      setCarpools(data);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to load carpools');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadCarpools();
  }, [sessionToken, festivalId]);

  const handleDeleteCarpool = async (carpoolId: string) => {
    try {
      await deleteCarpool(sessionToken, festivalId, carpoolId);
      await loadCarpools(); // Refresh the list after deletion
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to delete carpool');
    }
  };

  if (isLoading) {
    return <div>Loading carpools...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleString();
  };

  return (
    <div className="carpools-editor">
      <h2>Carpools</h2>
      <div className="carpools-table-container">
        <table className="carpools-table">
          <thead>
            <tr>
              <th>Created</th>
              <th>Direction</th>
              <th>Departure</th>
              <th>User</th>
              <th>Phone</th>
              <th>Note</th>
              <th>Coordinates</th>
              {!isViewerOnly && <th>Actions</th>}
            </tr>
          </thead>
          <tbody>
            {carpools.map(carpool => (
              <tr key={carpool.identifier}>
                <td>{formatDate(carpool.created_at)}</td>
                <td>{carpool.direction}</td>
                <td>{formatDate(carpool.departure_date)}</td>
                <td>{carpool.user_name}</td>
                <td>{carpool.user_phone}</td>
                <td>{carpool.note}</td>
                <td>
                  {carpool.external_address_coordinates.latitude.toFixed(6)}, 
                  {carpool.external_address_coordinates.longitude.toFixed(6)}
                </td>
                {!isViewerOnly && (
                  <td>
                    <button
                      className="delete-button"
                      onClick={() => setCarpoolToDelete(carpool)}
                    >
                      Delete
                    </button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {carpoolToDelete && (
        <DeleteConfirmModal
          carpool={carpoolToDelete}
          onConfirm={() => handleDeleteCarpool(carpoolToDelete.identifier)}
          onCancel={() => setCarpoolToDelete(null)}
        />
      )}
    </div>
  );
};

export default CarpoolsEditor; 