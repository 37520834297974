import React from 'react';

interface ThemeSectionProps {
  title: string;
  children: React.ReactNode;
}

const ThemeSection: React.FC<ThemeSectionProps> = ({ title, children }) => (
  <div className="form-group">
    <div className="theme-section">
      <h3>{title}</h3>
      <div className="theme-section-content">
        {children}
      </div>
    </div>
  </div>
);

export default ThemeSection; 