import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { performMigrations } from '../../../services/api';
import './Migrations.css';

const Migrations: React.FC = () => {
  const [masterKey, setMasterKey] = useState('');
  const [status, setStatus] = useState<{ type: 'success' | 'error', message: string } | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    setStatus(null);

    try {
      await performMigrations(masterKey);
      setStatus({ type: 'success', message: 'Migrations completed successfully' });
    } catch (err) {
      setStatus({ 
        type: 'error', 
        message: err instanceof Error ? err.message : 'Failed to perform migrations' 
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="migrations-container">
      <div className="migrations-content">
        <h1>Perform Migrations</h1>
        {status && (
          <div className={`status-message ${status.type}`}>
            {status.message}
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="masterKey">Master Key</label>
            <input
              type="password"
              id="masterKey"
              value={masterKey}
              onChange={(e) => setMasterKey(e.target.value)}
              required
            />
          </div>
          <div className="form-actions">
            <button 
              type="submit" 
              disabled={isLoading}
            >
              {isLoading ? 'Migrating...' : 'Migrate'}
            </button>
            <button 
              type="button" 
              onClick={() => history.push('/admin')} 
              className="back-button"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Migrations; 