import React, { useState } from 'react';
import { PUBLISHED_STATUS } from '../../../../model/FestivalData';

interface SaveButtonsContainerProps {
  unsavedChanges: Set<string>;
  lastSaveTime: Date | null;
  isSaving: boolean;
  onSave: () => void;
  status?: PUBLISHED_STATUS;
  onStatusChange?: (newStatus: PUBLISHED_STATUS) => void;
}

const SaveButtonsContainer: React.FC<SaveButtonsContainerProps> = ({
  unsavedChanges,
  lastSaveTime,
  isSaving,
  onSave,
  status,
  onStatusChange
}) => {
  const [showStatusDropdown, setShowStatusDropdown] = useState(false);

  const getSaveStatus = () => {
    if (unsavedChanges.size > 0) {
      return `${unsavedChanges.size} unsaved change${unsavedChanges.size > 1 ? 's' : ''}`;
    } else if (lastSaveTime) {
      return `All saved at ${lastSaveTime.toLocaleTimeString()}`;
    }
    return '';
  };

  return (
    <div className="save-status-container">
      <span className="save-status">{getSaveStatus()}</span>
      {status && onStatusChange && (
        <div className="status-dropdown-container">
          <button 
            className={`status-button ${status}`}
            onClick={() => setShowStatusDropdown(!showStatusDropdown)}
          >
            {status.charAt(0).toUpperCase() + status.slice(1)}
          </button>
          {showStatusDropdown && (
            <div className="status-dropdown">
              <button onClick={() => {
                onStatusChange('draft');
                setShowStatusDropdown(false);
              }}>Draft</button>
              <button onClick={() => {
                onStatusChange('published');
                setShowStatusDropdown(false);
              }}>Published</button>
            </div>
          )}
        </div>
      )}
      <button 
        onClick={onSave} 
        disabled={isSaving || unsavedChanges.size === 0} 
        className="save-button"
      >
        {isSaving ? 'Saving...' : 'Save Changes'}
      </button>
    </div>
  );
};

export default SaveButtonsContainer;
