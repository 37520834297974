import React, { useState, useMemo, useRef, useEffect } from 'react';
import { FestivalData, Show, Artist, POI, ImageData } from '../../../../model/FestivalData';
import { FaExclamationTriangle } from 'react-icons/fa';
import { generateCanonicalName, generateIdentifier } from '../../../../utils/identifiers';
import { getImageUrl } from '../../../../services/imageService';
import './ShowsEditor.css';

interface ShowsEditorProps {
  shows: FestivalData['shows'];
  artists: FestivalData['artists'];
  pois: FestivalData['pois'];
  onChange: (newShows: FestivalData['shows']) => void;
  getApiImageUrl: (identifier: string) => string;
}

interface ShowValidationErrors {
  dates?: string;
}

const ShowsEditor: React.FC<ShowsEditorProps> = ({ shows, artists, pois, onChange, getApiImageUrl }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [editingShow, setEditingShow] = useState<Show | null>(null);
  const [validationErrors, setValidationErrors] = useState<ShowValidationErrors>({});
  
  const [selectedDate, setSelectedDate] = useState<Date>(() => {
    if (shows.length === 0) return new Date();
    
    const earliestShow = shows.reduce((earliest, show) => {
      const showDate = new Date(show.start_date);
      return earliest === null || showDate < earliest ? showDate : earliest;
    }, null as Date | null);
    
    return earliestShow || new Date();
  });

  const [viewMode, setViewMode] = useState<'grid' | 'calendar'>('grid');
  const [selectedScene, setSelectedScene] = useState<string>('');

  const filteredShows = useMemo(() => {
    return shows.filter(show => {
      const artist = artists.find(a => a.identifier === show.artist_id);
      const poi = pois.find(p => p.identifier === show.scene_id);
      
      if (!searchTerm && !selectedScene) {
        return true;
      }

      const matchesSearch = !searchTerm || (
        (artist?.name.toLowerCase().includes(searchTerm.toLowerCase()) || false) ||
        (poi?.name.toLowerCase().includes(searchTerm.toLowerCase()) || false) ||
        (!artist && "Unknown Artist".toLowerCase().includes(searchTerm.toLowerCase())) ||
        (!poi && "Unknown Scene".toLowerCase().includes(searchTerm.toLowerCase()))
      );

      const matchesScene = !selectedScene || show.scene_id === selectedScene;

      return matchesSearch && matchesScene;
    });
  }, [shows, artists, pois, searchTerm, selectedScene]);

  const scenes = useMemo(() => {
    return pois.filter(poi => poi.category === 'scene');
  }, [pois]);

  const unsavedShowId = "unsaved_show_id";

  const handleAddShow = () => {
    const newShow: Show = {
      identifier: unsavedShowId,
      scene_id: '',
      artist_id: '',
      start_date: new Date().toISOString(),
      end_date: new Date(new Date().getTime() + 60 * 60 * 1000).toISOString() // Default to 1 hour duration
    };
    setEditingShow(newShow);
  };

  const handleEditShow = (show: Show) => {
    setEditingShow(show);
  };

  const handleSaveShow = (updatedShow: Show) => {
    const startDate = new Date(updatedShow.start_date);
    const endDate = new Date(updatedShow.end_date);
    
    if (startDate >= endDate) {
      setValidationErrors({
        dates: "Show start time must be before end time"
      });
      return;
    }

    setValidationErrors({});
    
    const artist = artists.find(a => a.identifier === updatedShow.artist_id);
    const canonicalName = artist 
      ? generateCanonicalName(artist.name)
      : 'unknown_artist';
    
    const newIdentifier = updatedShow.identifier === unsavedShowId
      ? generateIdentifier(canonicalName)
      : updatedShow.identifier;
    
    const finalShow = {
      ...updatedShow,
      identifier: newIdentifier
    };

    const updatedShows = shows.map(show => 
      show.identifier === updatedShow.identifier ? finalShow : show
    );

    if (!updatedShows.some(show => show.identifier === updatedShow.identifier)) {
      updatedShows.push(finalShow);
    }

    onChange(updatedShows);
    setEditingShow(null);
  };

  const handleRemoveShow = (identifier: string) => {
    if (window.confirm('Are you sure you want to delete this show?')) {
      const updatedShows = shows.filter(show => show.identifier !== identifier);
      onChange(updatedShows);
      setEditingShow(null);
    }
  };

  const validateShowDates = (show: Show): ShowValidationErrors => {
    const startDate = new Date(show.start_date);
    const endDate = new Date(show.end_date);
    
    if (startDate >= endDate) {
      return { dates: "Show start time must be before end time" };
    }
    return {};
  };

  const handleDateTimeChange = (field: 'start_date' | 'end_date', dateOrTime: string, isDate: boolean) => {
    if (editingShow) {
      const currentDate = new Date(editingShow[field]);
      let newDate: Date;

      if (isDate) {
        newDate = new Date(dateOrTime);
        if (isNaN(newDate.getTime())) return; // Invalid date, don't update
        newDate.setHours(currentDate.getHours(), currentDate.getMinutes());
      } else {
        const [hours, minutes] = dateOrTime.split(':').map(Number);
        if (isNaN(hours) || isNaN(minutes)) return; // Invalid time, don't update
        newDate = new Date(currentDate);
        newDate.setHours(hours, minutes);
      }

      const updatedShow = { ...editingShow, [field]: newDate.toISOString() };
      setEditingShow(updatedShow);
      setValidationErrors(validateShowDates(updatedShow));
    }
  };

  const formatDateForInput = (dateString: string) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const formatTimeForInput = (dateString: string) => {
    const date = new Date(dateString);
    return date.toTimeString().slice(0, 5);
  };

  const calendarShows = useMemo(() => {
    return shows.filter(show => {
      const showDate = new Date(show.start_date);
      return showDate.toDateString() === selectedDate.toDateString();
    });
  }, [shows, selectedDate]);

  const formatDateHeader = (date: Date) => {
    return date.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });
  };

  const getArtistImageSrc = (artist: Artist): string | undefined => {
    return getImageUrl(artist.main_image, getApiImageUrl);
  };

  const renderGrid = () => (
    <div className="shows-grid">
      {filteredShows.map(show => {
        const artist = artists.find(a => a.identifier === show.artist_id);
        const poi = pois.find(p => p.identifier === show.scene_id);
        const hasWarning = !artist || !poi;
        const imageSrc = artist ? getArtistImageSrc(artist) : undefined;
        
        return (
          <div 
            key={show.identifier} 
            className={`show-card ${hasWarning ? 'show-card-warning' : ''}`} 
            onClick={() => handleEditShow(show)}
          >
            {hasWarning && (
              <div className="warning-icon">
                <FaExclamationTriangle />
              </div>
            )}
            {imageSrc && artist && (
              <div className="card-image-container">
                <img src={imageSrc} alt={artist.name} className="card-image" />
              </div>
            )}
            <div className="card-content">
              <h3 className="card-title">{artist?.name || 'Unknown Artist'}</h3>
              <p className="card-subtitle">{poi?.name || 'Unknown Scene'}</p>
              <p className="card-subtitle">
                {new Date(show.start_date).toLocaleString()} - {new Date(show.end_date).toLocaleString()}
              </p>
            </div>
          </div>
        );
      })}
      <div className="show-card add-card" onClick={handleAddShow}>
        <span>+</span>
        <h3>Add Show</h3>
      </div>
    </div>
  );

  const renderCalendar = () => {
    const hours = Array.from({ length: 24 }, (_, i) => i);
    const stages = pois.filter(poi => poi.category === 'scene');

    return (
      <div className="calendar-view">
        <div className="calendar-header">
          <button onClick={() => setSelectedDate(new Date(selectedDate.getTime() - 86400000))}>
            Previous Day
          </button>
          <h3>{formatDateHeader(selectedDate)}</h3>
          <button onClick={() => setSelectedDate(new Date(selectedDate.getTime() + 86400000))}>
            Next Day
          </button>
        </div>
        <div className="calendar-grid">
          <div className="calendar-times">
            {hours.map(hour => (
              <div key={hour} className="calendar-hour">{`${hour}:00`}</div>
            ))}
          </div>
          {stages.map(stage => (
            <div key={stage.identifier} className="calendar-stage">
              <div className="stage-name">{stage.name}</div>
              <div className="stage-shows">
                {calendarShows
                  .filter(show => show.scene_id === stage.identifier)
                  .map(show => {
                    const artist = artists.find(a => a.identifier === show.artist_id);
                    const poi = pois.find(p => p.identifier === show.scene_id);
                    const startTime = new Date(show.start_date);
                    const endTime = new Date(show.end_date);
                    const top = (startTime.getHours() + startTime.getMinutes() / 60) * 60;
                    const height = ((endTime.getTime() - startTime.getTime()) / (1000 * 60 * 60)) * 60;
                    const calendarShow = (
                      <div
                        key={show.identifier}
                        className={`calendar-show ${!artist || !poi ? 'calendar-show-warning' : ''}`}
                        style={{
                          top: `${top}px`,
                          height: `${height}px`,
                        }}
                        onClick={() => handleEditShow(show)}
                      >
                        {(!artist || !poi) && (
                          <div className="warning-icon">
                            <FaExclamationTriangle />
                          </div>
                        )}
                        <div className="show-info">
                          <strong>{artist?.name || 'Unknown Artist'}</strong>
                          <br />
                          {`${formatTimeForInput(show.start_date)} - ${formatTimeForInput(show.end_date)}`}
                        </div>
                      </div>
                    );
                    return calendarShow;
                  })}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderFilters = () => (
    <div className="shows-filters">
      <div className="filter-group">
        <div className="search-bar">
          <input
            type="text"
            placeholder="Search shows..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="scene-filter">
          <select
            value={selectedScene}
            onChange={(e) => setSelectedScene(e.target.value)}
          >
            <option value="">All Scenes</option>
            {scenes.map(scene => (
              <option key={scene.identifier} value={scene.identifier}>
                {scene.name}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="view-toggle">
        <button 
          onClick={() => setViewMode('grid')} 
          className={viewMode === 'grid' ? 'active' : ''}
        >
          Grid View
        </button>
        <button 
          onClick={() => setViewMode('calendar')} 
          className={viewMode === 'calendar' ? 'active' : ''}
        >
          Calendar View
        </button>
      </div>
    </div>
  );

  // Add ref for modal content
  const modalRef = useRef<HTMLDivElement>(null);

  // Add useClickOutside hook
  const handleClickOutside = (e: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
      setEditingShow(null);
      setValidationErrors({});
    }
  };

  useEffect(() => {
    if (editingShow) {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }
  }, [editingShow]);

  return (
    <section className="shows-editor">
      <h2>Shows</h2>
      {renderFilters()}
      {viewMode === 'grid' ? renderGrid() : renderCalendar()}
      <button onClick={handleAddShow} className="add-show-button">Add Show</button>
      {editingShow && (
        <div className="modal-overlay">
          <div className="modal-content" ref={modalRef}>
            <h3>{editingShow.identifier ? 'Edit Show' : 'Add Show'}</h3>
            
            {validationErrors.dates && (
              <div className="error-message" style={{ color: 'red', marginBottom: '1rem' }}>
                {validationErrors.dates}
              </div>
            )}

            <select
              value={editingShow.artist_id}
              onChange={(e) => setEditingShow({ ...editingShow, artist_id: e.target.value })}
            >
              <option value="">Select an artist</option>
              {artists.map((artist) => (
                <option key={artist.identifier} value={artist.identifier}>{artist.name}</option>
              ))}
            </select>
            <select
              value={editingShow.scene_id}
              onChange={(e) => setEditingShow({ ...editingShow, scene_id: e.target.value })}
            >
              <option value="">Select a scene</option>
              {pois.filter((poi) => poi.category === 'scene').map((poi) => (
                <option key={poi.identifier} value={poi.identifier}>{poi.name}</option>
              ))}
            </select>
            <div className="date-time-inputs">
              <label>Start Date and Time:</label>
              <input
                type="date"
                value={formatDateForInput(editingShow.start_date)}
                onChange={(e) => handleDateTimeChange('start_date', e.target.value, true)}
              />
              <input
                type="time"
                value={formatTimeForInput(editingShow.start_date)}
                onChange={(e) => handleDateTimeChange('start_date', e.target.value, false)}
              />
            </div>
            <div className="date-time-inputs">
              <label>End Date and Time:</label>
              <input
                type="date"
                value={formatDateForInput(editingShow.end_date)}
                onChange={(e) => handleDateTimeChange('end_date', e.target.value, true)}
              />
              <input
                type="time"
                value={formatTimeForInput(editingShow.end_date)}
                onChange={(e) => handleDateTimeChange('end_date', e.target.value, false)}
              />
            </div>
            <div className="modal-actions">
              <button 
                onClick={() => handleSaveShow(editingShow)}
                disabled={!!validationErrors.dates}
                style={{ opacity: validationErrors.dates ? 0.5 : 1 }}
              >
                Save
              </button>
              <button onClick={() => {
                setValidationErrors({});
                setEditingShow(null);
              }}>Cancel</button>
              {editingShow.identifier && (
                <button onClick={() => handleRemoveShow(editingShow.identifier)}>Remove</button>
              )}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default ShowsEditor;
