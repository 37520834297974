import React from 'react';
import { FestivalData } from '../../../../model/FestivalData';

interface TicketsEditorProps {
  tickets: FestivalData['infos']['tickets'];
  onChange: (newTickets: NonNullable<FestivalData['infos']['tickets']>) => void;
  getApiImageUrl: (identifier: string) => string;
}

const TicketsEditor: React.FC<TicketsEditorProps> = ({ tickets, onChange }) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    onChange({
      ...tickets!,
      [name]: value
    });
  };

  return (
    <section className="tickets-editor">
      <h2>Tickets Information</h2>
      <p className="field-description">Information about festival tickets.</p>
      
      <div className="form-group">
        <label htmlFor="ticket_description">Ticket Description, HTML formatting supported (Eg:  &lt;b&gt;bold&lt;/b&gt;, &lt;i&gt;italic&lt;/i&gt;):</label>
        <textarea
          id="ticket_description"
          name="description"
          value={tickets?.description || ''}
          onChange={handleChange}
          rows={4}
          className="ticket-description"
        />
      </div>
      <div className="form-group">
        <label htmlFor="ticket_link">Ticket Link:</label>
        <input
          placeholder='https://www.ticketmaster.fr/event/1234567890'
          id="ticket_link"
          type="text"
          name="link"
          value={tickets?.link || ''}
          onChange={handleChange}
          className="ticket-link"
        />
      </div>
    </section>
  );
};

export default TicketsEditor;
