export enum Role {
  ADMIN = "admin",
  NEWS = "news",
  NEWS_WRITE = "news.write",
  PAGES = "pages",
  PAGES_WRITE = "pages.write",
  PAGES_READ = "pages.read",
  NOTIFICATION = "notification",
  NOTIFICATION_PUSH = "notification.push",
  CARPOOLS = "carpools",
  CARPOOLS_VIEWER = "carpools.viewer",
  ADVERTISEMENTS = "advertisements",
  ADVERTISEMENTS_WRITE = "advertisements.write",
  ADVERTISEMENTS_READ = "advertisements.read",
  MODERATOR = "moderator",
  MODERATOR_CARPOOLS = "moderator.carpools",
}

export const SETTABLE_ROLES = [
  { value: Role.ADMIN, label: "Administrator" },
  { value: Role.NEWS_WRITE, label: "News Editor" },
  { value: Role.PAGES_WRITE, label: "Pages Editor" },
  { value: Role.ADVERTISEMENTS_WRITE, label: "Advertisements Editor" },
  { value: Role.NOTIFICATION_PUSH, label: "Notifications" },
  { value: Role.CARPOOLS, label: "Carpools admin" },
  { value: Role.CARPOOLS_VIEWER, label: "Carpools viewer" },
  { value: Role.MODERATOR, label: "Moderator" },
];

export type EditorType = 'infos' | 'news' | 'pois' | 'artists' | 'shows' | 'map' | 'tickets' | 'history' | 'notifications' | 'users' | 'carpools' | 'reporting' | 'pages' | 'advertisements';

export const getAccessibleEditors = (roles: string[]): EditorType[] => {
  const allowedEditors = new Set<EditorType>();

  if (roles.includes(Role.ADMIN)) {
    return ['infos', 'news', 'pois', 'artists', 'shows', 'map', 'tickets', 'history', 'notifications', 'users', 'carpools', 'reporting', 'pages', 'advertisements'];
  }

  // Add pages access
  if (roles.some(role => role.startsWith('pages'))) {
    allowedEditors.add('pages');
  }

  // Add moderator access
  if (roles.includes(Role.MODERATOR)) {
    allowedEditors.add('reporting');
  }

  // News related roles
  if (roles.some(role => role.startsWith('news'))) {
    allowedEditors.add('news');
  }

  // Notification related roles
  if (roles.some(role => role.startsWith('notification'))) {
    allowedEditors.add('notifications');
  }

  // Carpools related roles
  if (roles.some(role => role.startsWith('carpools'))) {
    allowedEditors.add('carpools');
  }

  // Advertisements related roles
  if (roles.some(role => role.startsWith('advertisements'))) {
    allowedEditors.add('advertisements');
  }

  return Array.from(allowedEditors);
}; 