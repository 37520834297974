import React from 'react';

interface ColorInputProps {
  id: string;
  name: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  label: string;
}

const ColorInput: React.FC<ColorInputProps> = ({
  id,
  name,
  value,
  onChange,
  placeholder = '#000000',
  label,
}) => (
  <div className="form-group">
    <label htmlFor={id}>{label}</label>
    <div className="color-input-wrapper">
      <input
        id={id}
        type="color"
        name={name}
        value={value}
        onChange={onChange}
      />
      <input
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  </div>
);

export default ColorInput; 