import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FestivalData, Artist, ImageData } from '../../../../model/FestivalData';
import ImageEditor from '../ImageEditor/ImageEditor';
import { imageUrl } from '../../../../services/api';
import { generateCanonicalName, generateIdentifier } from '../../../../utils/identifiers';
import Modal from '../../../../components/Modal';
import { getImageUrl } from '../../../../services/imageService';

interface ArtistsEditorProps {
  artists: FestivalData['artists'];
  onChange: (newArtists: FestivalData['artists']) => void;
  getApiImageUrl: (identifier: string) => string;
}

interface MusicPreviewErrors {
  spotify_url: string | null;
  apple_music_url: string | null;
  youtube_url: string | null;
}

interface UrlValidationParams {
  domain: string;
  requiredPaths?: string[];
  requireParams?: string[];
  allowedHosts?: string[];
  example: string;
}

const VALIDATION_PARAMS: Record<keyof MusicPreviewErrors, UrlValidationParams> = {
  spotify_url: {
    domain: 'spotify',
    requiredPaths: ['/track/', '/album/', '/artist/'],
    allowedHosts: ['spotify.com'],
    example: 'https://open.spotify.com/artist/...'
  },
  apple_music_url: {
    domain: 'apple',
    requiredPaths: ['/album/', '/artist/'],
    allowedHosts: ['music.apple.com'],
    example: 'https://music.apple.com/artist/...'
  },
  youtube_url: {
    domain: 'youtube',
    requireParams: ['v'],
    allowedHosts: ['youtube.com', 'youtu.be'],
    example: 'https://youtube.com/watch?v=... or https://youtu.be/...'
  }
};

const isValidUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

const ArtistsEditor: React.FC<ArtistsEditorProps> = ({ artists, onChange, getApiImageUrl }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [editingArtist, setEditingArtist] = useState<Artist | null>(null);
  const [unsavedChanges, setUnsavedChanges] = useState<Partial<Artist> | null>(null);
  const [tempImages, setTempImages] = useState<Record<string, string>>({});
  const [musicPreviewErrors, setMusicPreviewErrors] = useState<MusicPreviewErrors>({
    spotify_url: null,
    apple_music_url: null,
    youtube_url: null,
  });

  const filteredArtists = artists.filter(artist => 
    artist.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const unsavedArtistId = "unsaved_artist_id"

  const handleAddArtist = () => {
    const newArtist: Artist = {
      identifier: unsavedArtistId,
      name: 'New Artist',
      description: '',
      style: '',
      main_image: null,
      music_preview: {
        spotify_url: null,
        apple_music_url: null,
        youtube_url: null
      }
    };
    setEditingArtist(newArtist);
    setUnsavedChanges(null);
    setTempImages({});
  };

  const handleEditArtist = (artist: Artist) => {
    setEditingArtist({...artist});
    setUnsavedChanges(null);
    setTempImages({});
  };

  const handleImageChange = (newImage: ImageData | undefined) => {
    if (editingArtist) {
      setUnsavedChanges(prev => ({
        ...prev,
        main_image: newImage || null
      }));

      if (newImage && newImage.type === 'ApiImage' && 'file' in newImage && newImage.file) {
        setTempImages(prev => ({
          ...prev,
          [editingArtist.identifier]: URL.createObjectURL(newImage.file as Blob)
        }));
      } else {
        setTempImages(prev => {
          const { [editingArtist.identifier]: removed, ...rest } = prev;
          return rest;
        });
      }
    }
  };

  const handleSaveArtist = useCallback((artistToSave: Artist) => {
    const canonicalName = generateCanonicalName(artistToSave.name);
    
    const newIdentifier = artistToSave.identifier === unsavedArtistId
      ? generateIdentifier(canonicalName)
      : artistToSave.identifier;
    
    const updatedArtist: Artist = { 
      ...artistToSave,
      ...unsavedChanges,
      identifier: newIdentifier
    };
    
    const updatedArtists = artists.map(artist => 
      artist.identifier === artistToSave.identifier ? updatedArtist : artist 
    );

    if (!updatedArtists.some(artist => artist.identifier === artistToSave.identifier)) {
      updatedArtists.push(updatedArtist);
    }

    onChange(updatedArtists);
    setEditingArtist(null);
    setUnsavedChanges(null);
    setTempImages({});
  }, [artists, onChange, unsavedChanges]);

  const handleRemoveArtist = (identifier: string) => {
    const artistToRemove = artists.find(artist => artist.identifier === identifier);
    if (artistToRemove && window.confirm(`Are you sure you want to delete the artist "${artistToRemove.name}"?`)) {
      const updatedArtists = artists.filter(artist => artist.identifier !== identifier);
      onChange(updatedArtists);
      setEditingArtist(null);
    }
  };

  const getImageSrc = (artist: Artist): string | undefined => {
    if (tempImages[artist.identifier]) {
      return tempImages[artist.identifier];
    }
    return getImageUrl(artist.main_image, getApiImageUrl);
  };

  const getModalImageSrc = (artist: Artist): string | null => {
    if (tempImages[artist.identifier]) {
      return tempImages[artist.identifier];
    }
    return getImageUrl(artist.main_image, getApiImageUrl) || null;
  };

  const validateUrl = (url: string, validationParams: UrlValidationParams): string | null => {
    if (!url.trim()) return null;
    
    const { domain, requiredPaths, requireParams, allowedHosts, example } = validationParams;
    const addExample = (msg: string) => `${msg}\nExample: ${example}`;
    
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return addExample('URL must start with http:// or https://');
    }

    try {
      const parsedUrl = new URL(url);

      if (allowedHosts && !allowedHosts.some(host => parsedUrl.hostname.includes(host))) {
        return addExample(`Invalid ${domain} URL format`);
      }

      if (domain === 'youtube' && parsedUrl.hostname === 'youtu.be') {
        return parsedUrl.pathname.length <= 1 ? addExample('Invalid YouTube video ID') : null;
      }

      if (requiredPaths && !requiredPaths.some(path => parsedUrl.pathname.includes(path))) {
        return addExample(`${domain} URL must link to ${requiredPaths.map(p => p.replace(/\//g, '')).join(', ')}`);
      }

      if (requireParams && !requireParams.every(param => parsedUrl.searchParams.has(param))) {
        return addExample(`${domain} URL must include ${requireParams.join(', ')} parameter`);
      }

      return null;
    } catch {
      return addExample('Please enter a valid URL');
    }
  };

  return (
    <section className="artists-editor">
      <h2>Artists</h2>
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search artists..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      <div className="artists-grid">
        {filteredArtists.map(artist => (
          <div key={artist.identifier} className="artist-card" onClick={() => handleEditArtist(artist)}>
            {getImageSrc(artist) && (
              <img 
                src={getImageSrc(artist)!}
                alt={artist.name}
                className="card-image"
              />
            )}
            <div className="card-content">
              <h3 className="card-title">{artist.name}</h3>
              <p className="card-subtitle">{artist.style}</p>
            </div>
          </div>
        ))}
        <div className="artist-card add-card" onClick={handleAddArtist}>
          <span>+</span>
          <h3>Add Artist</h3>
        </div>
      </div>
      
      <Modal 
        isOpen={editingArtist !== null}
        onClose={() => {
          setEditingArtist(null);
          setUnsavedChanges(null);
          setTempImages({});
        }}
      >
        {editingArtist && (
          <>
            <h3>{editingArtist.identifier === generateCanonicalName('New Artist') ? 'Add Artist' : 'Edit Artist'}</h3>
            <input
              type="text"
              value={editingArtist.name}
              onChange={(e) => {
                if (!editingArtist) return;
                setEditingArtist({
                  ...editingArtist,
                  name: e.target.value
                });
              }}
              placeholder="Artist Name"
            />
            <textarea
              value={editingArtist.description}
              onChange={(e) => {
                if (!editingArtist) return;
                setEditingArtist({
                  ...editingArtist,
                  description: e.target.value
                });
              }}
              placeholder="Artist Description"
            />
            <input
              type="text"
              value={editingArtist.style}
              onChange={(e) => {
                if (!editingArtist) return;
                setEditingArtist({
                  ...editingArtist,
                  style: e.target.value
                });
              }}
              placeholder="Artist Style"
            />
            <ImageEditor
              currentImage={editingArtist.main_image || undefined}
              onChange={handleImageChange}
              getApiImageUrl={getApiImageUrl}
              tempPreviewUrl={tempImages[editingArtist.identifier]}
            />
            <h4>Music Preview</h4>
            <div className="music-preview-section">
              <div className="input-group">
                <input
                  type="text"
                  value={editingArtist.music_preview.spotify_url || ''}
                  onChange={(e) => {
                    if (!editingArtist) return;
                    const url = e.target.value;
                    const error = url.trim() ? validateUrl(url, VALIDATION_PARAMS.spotify_url) : null;
                    setMusicPreviewErrors(prev => ({
                      ...prev,
                      spotify_url: url.trim() ? error : null
                    }));
                    setEditingArtist({
                      ...editingArtist,
                      music_preview: {
                        ...editingArtist.music_preview,
                        spotify_url: url.trim()
                      }
                    });
                  }}
                  onBlur={(e) => {
                    if (!editingArtist) return;
                    const url = e.target.value.trim();
                    const error = url ? validateUrl(url, VALIDATION_PARAMS.spotify_url) : null;
                    setMusicPreviewErrors(prev => ({
                      ...prev,
                      spotify_url: url ? error : null
                    }));
                    setEditingArtist({
                      ...editingArtist,
                      music_preview: {
                        ...editingArtist.music_preview,
                        spotify_url: url && !error ? url : null
                      }
                    });
                  }}
                  placeholder="Spotify URL"
                  className={musicPreviewErrors.spotify_url ? 'error' : ''}
                />
                {musicPreviewErrors.spotify_url && (
                  <div className="error-message">{musicPreviewErrors.spotify_url}</div>
                )}
              </div>

              <div className="input-group">
                <input
                  type="text"
                  value={editingArtist.music_preview.apple_music_url || ''}
                  onChange={(e) => {
                    if (!editingArtist) return;
                    const url = e.target.value;
                    const error = url.trim() ? validateUrl(url, VALIDATION_PARAMS.apple_music_url) : null;
                    setMusicPreviewErrors(prev => ({
                      ...prev,
                      apple_music_url: url.trim() ? error : null
                    }));
                    setEditingArtist({
                      ...editingArtist,
                      music_preview: {
                        ...editingArtist.music_preview,
                        apple_music_url: url.trim()
                      }
                    });
                  }}
                  onBlur={(e) => {
                    if (!editingArtist) return;
                    const url = e.target.value.trim();
                    const error = url ? validateUrl(url, VALIDATION_PARAMS.apple_music_url) : null;
                    setMusicPreviewErrors(prev => ({
                      ...prev,
                      apple_music_url: url ? error : null
                    }));
                    setEditingArtist({
                      ...editingArtist,
                      music_preview: {
                        ...editingArtist.music_preview,
                        apple_music_url: url && !error ? url : null
                      }
                    });
                  }}
                  placeholder="Apple Music URL"
                  className={musicPreviewErrors.apple_music_url ? 'error' : ''}
                />
                {musicPreviewErrors.apple_music_url && (
                  <div className="error-message">{musicPreviewErrors.apple_music_url}</div>
                )}
              </div>

              <div className="input-group">
                <input
                  type="text"
                  value={editingArtist.music_preview.youtube_url || ''}
                  onChange={(e) => {
                    if (!editingArtist) return;
                    const url = e.target.value;
                    const error = url.trim() ? validateUrl(url, VALIDATION_PARAMS.youtube_url) : null;
                    setMusicPreviewErrors(prev => ({
                      ...prev,
                      youtube_url: url.trim() ? error : null
                    }));
                    setEditingArtist({
                      ...editingArtist,
                      music_preview: {
                        ...editingArtist.music_preview,
                        youtube_url: url.trim()
                      }
                    });
                  }}
                  onBlur={(e) => {
                    if (!editingArtist) return;
                    const url = e.target.value.trim();
                    const error = url ? validateUrl(url, VALIDATION_PARAMS.youtube_url) : null;
                    setMusicPreviewErrors(prev => ({
                      ...prev,
                      youtube_url: url ? error : null
                    }));
                    setEditingArtist({
                      ...editingArtist,
                      music_preview: {
                        ...editingArtist.music_preview,
                        youtube_url: url && !error ? url : null
                      }
                    });
                  }}
                  placeholder="YouTube URL"
                  className={musicPreviewErrors.youtube_url ? 'error' : ''}
                />
                {musicPreviewErrors.youtube_url && (
                  <div className="error-message">{musicPreviewErrors.youtube_url}</div>
                )}
              </div>
            </div>
            <div className="modal-actions">
              <button 
                onClick={() => {
                  if (editingArtist) {
                    handleSaveArtist(editingArtist);
                  }
                }}
                disabled={Object.values(musicPreviewErrors).some(error => error !== null)}
              >
                Save
              </button>
              <button onClick={() => {
                setEditingArtist(null);
                setUnsavedChanges(null);
                setTempImages({});
              }}>Cancel</button>
              {editingArtist && editingArtist.identifier !== generateCanonicalName('New Artist') && (
                <button onClick={() => handleRemoveArtist(editingArtist.identifier)}>Remove</button>
              )}
            </div>
          </>
        )}
      </Modal>
    </section>
  );
};

export default ArtistsEditor;
