import React from 'react';
import { EditorType } from '../../../../model/Roles';
import './EditorMenu.css';

interface EditorMenuProps {
  allowedEditors: EditorType[];
  activeEditor: EditorType;
  onTabChange: (newTab: EditorType) => void;
}

const EditorMenu: React.FC<EditorMenuProps> = ({ allowedEditors, activeEditor, onTabChange }) => {
  const festivalEditors: EditorType[] = ['infos', 'map', 'tickets', 'pois', 'artists', 'shows', 'history'];
  const contentEditors: EditorType[] = ['pages', 'news', 'notifications', 'users', 'carpools', 'reporting', 'advertisements'];

  return (
    <nav className="editor-menu">
      <div className="editor-section">
        <h2 className="section-title">Festival</h2>
        <ul>
          {festivalEditors.map(editor => 
            allowedEditors.includes(editor) && (
              <li 
                key={editor}
                className={activeEditor === editor ? 'active' : ''} 
                onClick={() => onTabChange(editor)}
              >
                {editor === 'infos' && 'Info & Layout'}
                {editor === 'map' && 'Maps'}
                {editor === 'pois' && 'POIs'}
                {editor === 'artists' && 'Artists'}
                {editor === 'shows' && 'Shows'}
                {editor === 'tickets' && 'Tickets'}
                {editor === 'history' && 'History'}
              </li>
            )
          )}
        </ul>
      </div>

      <div className="editor-section">
        <h2 className="section-title">Content</h2>
        <ul>
          {contentEditors.map(editor => 
            allowedEditors.includes(editor) && (
              <li 
                key={editor}
                className={activeEditor === editor ? 'active' : ''} 
                onClick={() => onTabChange(editor)}
              >
                {editor === 'news' && 'News'}
                {editor === 'notifications' && 'Notifications'}
                {editor === 'users' && 'Users'}
                {editor === 'carpools' && 'Carpools'}
                {editor === 'reporting' && 'Reports'}
                {editor === 'pages' && 'Pages'}
                {editor === 'advertisements' && 'Advertisements'}
              </li>
            )
          )}
        </ul>
      </div>
    </nav>
  );
};

export default EditorMenu; 