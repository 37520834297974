import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import ImageEditor from '../ImageEditor/ImageEditor';
import { ImageData } from '../../../../model/FestivalData';
import { fetchAdvertisements, updateAdvertisement, deleteAdvertisement } from '../../../../services/api';
import './AdvertisementsEditor.css';
import { Advertisement } from '../../../../model/Advertisement';
import { ImageService } from '../../../../services/imageService';

interface AdvertisementsEditorProps {
  sessionToken: string;
  festivalId: string;
  getApiImageUrl: (identifier: string) => string;
}

const URL_PATTERN = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

const AdvertisementsEditor: React.FC<AdvertisementsEditorProps> = ({
  sessionToken,
  festivalId,
  getApiImageUrl,
}) => {
  const [advertisements, setAdvertisements] = useState<Advertisement[]>([]);
  const [editingAd, setEditingAd] = useState<Advertisement | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [savingId, setSavingId] = useState<string | null>(null);
  const [deletingId, setDeletingId] = useState<string | null>(null);
  const [linkError, setLinkError] = useState<string | null>(null);

  const imageService = new ImageService(sessionToken, festivalId);

  // Calculate total weight and display chances
  const { totalWeight, displayChances, noDisplayChance } = useMemo(() => {
    const total = advertisements.reduce((sum, ad) => sum + ad.weight, 0);
    const chances = new Map<string, number>();
    
    advertisements.forEach(ad => {
      chances.set(ad.identifier, ad.weight * 100);
    });

    // Calculate chance of no ad being displayed
    const noDisplayChance = Math.max(0, (1 - total) * 100);

    return { totalWeight: total, displayChances: chances, noDisplayChance };
  }, [advertisements]);

  useEffect(() => {
    const loadAdvertisements = async () => {
      try {
        const data = await fetchAdvertisements(sessionToken, festivalId);
        setAdvertisements(data);
      } catch (error) {
        console.error('Failed to load advertisements:', error);
        setError('Failed to load advertisements');
      } finally {
        setLoading(false);
      }
    };
    loadAdvertisements();
  }, [sessionToken, festivalId]);

  const handleAddAdvertisement = useCallback(() => {
    const newAd: Advertisement = {
      identifier: uuidv4(),
      title: '',
      description: '',
      image: null,
      background_image: null,
      weight: 0.5,
      link: null,
    };
    setAdvertisements(prev => [...prev, newAd]);
    setEditingAd(newAd);
  }, []);

  const validateLink = (link: string | null): boolean => {
    if (!link) return true; // Empty or null link is valid
    return URL_PATTERN.test(link);
  };

  const handleSaveAdvertisement = async (ad: Advertisement) => {
    if (!validateLink(ad.link)) {
      setError('Invalid URL format');
      return;
    }

    try {
      setSavingId(ad.identifier);
      setError(null);

      // Handle image uploads before saving
      let adToSave = { ...ad };

      // Upload main image if needed
      if (ad.image) {
        const uploadedImage = await imageService.uploadImageData(ad.image);
        adToSave = {
          ...adToSave,
          image: uploadedImage
        };
      }

      // Upload background image if needed
      if (ad.background_image) {
        const uploadedBackgroundImage = await imageService.uploadImageData(ad.background_image);
        adToSave = {
          ...adToSave,
          background_image: uploadedBackgroundImage
        };
      }

      // Save the advertisement with uploaded images
      await updateAdvertisement(sessionToken, festivalId, adToSave);
      
      setAdvertisements(prev =>
        prev.map(item => (item.identifier === adToSave.identifier ? adToSave : item))
      );
      setEditingAd(null);
    } catch (error) {
      console.error('Failed to save advertisement:', error);
      setError(error instanceof Error ? error.message : 'Failed to save advertisement');
    } finally {
      setSavingId(null);
    }
  };

  const handleDeleteAdvertisement = async (identifier: string) => {
    try {
      setDeletingId(identifier);
      await deleteAdvertisement(sessionToken, festivalId, identifier);
      setAdvertisements(prev => prev.filter(ad => ad.identifier !== identifier));
      setEditingAd(null);
    } catch (error) {
      console.error('Failed to delete advertisement:', error);
      setError(error instanceof Error ? error.message : 'Failed to delete advertisement');
    } finally {
      setDeletingId(null);
    }
  };

  if (loading) {
    return <div>Loading advertisements...</div>;
  }

  return (
    <div className="advertisements-editor">
      <h2>Advertisements</h2>
      
      <div className="weight-summary">
        <div className={`total-weight ${totalWeight > 1 ? 'weight-warning' : ''}`}>
          <span>Total weight: {totalWeight.toFixed(2)}</span>
          {totalWeight > 1 && (
            <span className="weight-warning-text">
              Warning: Total weight exceeds 1
            </span>
          )}
        </div>
        
        <div className="weight-explanation">
          {advertisements.length === 0 ? (
            "Add advertisements to configure display chances"
          ) : (
            <>
              <div className="chances-header">Display chances at app launch:</div>
              <div className="chances-list">
                {Array.from(displayChances.entries()).map(([id, chance]) => {
                  const ad = advertisements.find(a => a.identifier === id);
                  return (
                    <div key={id} className="chance-item">
                      <span className="chance-title">{ad?.title || 'Untitled'}</span>
                      <span className="chance-value">{chance.toFixed(1)}%</span>
                    </div>
                  );
                })}
                {totalWeight <= 1 && (
                  <div className="chance-item no-display">
                    <span className="chance-title">No advertisement</span>
                    <span className="chance-value">{noDisplayChance.toFixed(1)}%</span>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <button onClick={handleAddAdvertisement} className="add-ad-button">
        Add Advertisement
      </button>
      {error && <div className="error-message">{error}</div>}
      <ul className="advertisements-list">
        {advertisements.map(ad => (
          <li key={ad.identifier} className="advertisement-item">
            <div
              className="advertisement-preview"
              onClick={() => setEditingAd(editingAd?.identifier === ad.identifier ? null : ad)}
            >
              <div className="advertisement-info">
                <h3>{ad.title || 'Untitled Advertisement'}</h3>
                <p className="advertisement-description">{ad.description}</p>
                {ad.link && (
                  <a 
                    href={ad.link}
                    className="advertisement-link"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e => e.stopPropagation()}
                  >
                    {ad.link}
                  </a>
                )}
              </div>
              <div className="advertisement-stats">
                <span className="weight-display">Weight: {ad.weight}</span>
                <span className="chance-display">
                  ({displayChances.get(ad.identifier)?.toFixed(1)}% chance)
                </span>
              </div>
            </div>
            {editingAd?.identifier === ad.identifier && (
              <div className="advertisement-edit-form">
                <input
                  type="text"
                  value={editingAd.title}
                  onChange={e => setEditingAd({ ...editingAd, title: e.target.value })}
                  placeholder="Title"
                />
                <textarea
                  value={editingAd.description}
                  onChange={e => setEditingAd({ ...editingAd, description: e.target.value })}
                  placeholder="Description"
                />
                <div className="link-input">
                  <label>Link (optional):</label>
                  <input
                    type="url"
                    value={editingAd.link || ''}
                    onChange={e => {
                      const newLink = e.target.value;
                      setEditingAd({ 
                        ...editingAd, 
                        link: newLink.trim() === '' ? null : newLink 
                      });
                      setLinkError(validateLink(newLink) ? null : 'Invalid URL format');
                    }}
                    placeholder="https://example.com"
                    className={linkError ? 'error' : ''}
                  />
                  {linkError && <div className="link-error">{linkError}</div>}
                </div>
                <div className="weight-input">
                  <label>Weight (0-1):</label>
                  <input
                    type="number"
                    min="0"
                    max="1"
                    step="0.1"
                    value={editingAd.weight}
                    onChange={e => setEditingAd({ ...editingAd, weight: parseFloat(e.target.value) })}
                  />
                </div>
                
                <div className="advertisement-images">
                  <div className="image-editor-container">
                    <div className="image-editor-description">
                      <h4>Main Image</h4>
                      <p>This image will be displayed in the advertisement popup</p>
                    </div>
                    <ImageEditor
                      currentImage={editingAd.image || undefined}
                      onChange={newImage => setEditingAd({ ...editingAd, image: newImage || null })}
                      getApiImageUrl={getApiImageUrl}
                    />
                  </div>

                  <div className="image-editor-container">
                    <div className="image-editor-description">
                      <h4>Background Image</h4>
                      <p>This image will be displayed behind the advertisement content</p>
                    </div>
                    <ImageEditor
                      currentImage={editingAd.background_image || undefined}
                      onChange={newImage => setEditingAd({ ...editingAd, background_image: newImage || null })}
                      getApiImageUrl={getApiImageUrl}
                    />
                  </div>
                </div>

                <div className="advertisement-actions">
                  <button
                    onClick={() => handleSaveAdvertisement(editingAd)}
                    disabled={savingId === ad.identifier}
                  >
                    {savingId === ad.identifier ? 'Saving...' : 'Save'}
                  </button>
                  <button onClick={() => setEditingAd(null)}>Cancel</button>
                  <button
                    onClick={() => handleDeleteAdvertisement(ad.identifier)}
                    disabled={deletingId === ad.identifier}
                    className="delete-button"
                  >
                    {deletingId === ad.identifier ? 'Deleting...' : 'Delete'}
                  </button>
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AdvertisementsEditor; 