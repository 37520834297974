import React from 'react';

interface NumberInputProps {
  id: string;
  name: string;
  value: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  min?: number;
  step?: number;
  label: string;
  description: string;
}

const NumberInput: React.FC<NumberInputProps> = ({
  id,
  name,
  value,
  onChange,
  min = 0,
  step = 1,
  label,
  description,
}) => (
  <div className="form-group">
    <label htmlFor={id}>{label}</label>
    <div className="number-input-wrapper">
      <input
        id={id}
        type="number"
        name={name}
        value={value}
        onChange={onChange}
        min={min}
        step={step}
      />
      <span className="field-description">{description}</span>
    </div>
  </div>
);

export default NumberInput; 