import React, { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import { Page, ImageData } from '../../../../model/FestivalData';
import { fetchPages, updatePage, deletePage } from '../../../../services/api';
import { formatDateWithoutMilliseconds } from '../../../../utils/dateUtils';
import { v4 as uuidv4 } from 'uuid';
import ImageEditor from '../ImageEditor/ImageEditor';
import './PagesEditor.css';
import { getImageUrl } from '../../../../services/imageService';
import { ImageService } from '../../../../services/imageService';

interface PagesEditorProps {
  sessionToken: string;
  festivalId: string;
  getApiImageUrl: (identifier: string) => string;
}

const PagesEditor: React.FC<PagesEditorProps> = ({ 
  sessionToken, 
  festivalId,
  getApiImageUrl,
}) => {
  const [pages, setPages] = useState<Page[]>([]);
  const [editingPage, setEditingPage] = useState<Page | null>(null);
  const [loading, setLoading] = useState(true);
  const [saveError, setSaveError] = useState<string | null>(null);
  const [savingIdentifier, setSavingIdentifier] = useState<string | null>(null);
  const [deletingIdentifier, setDeletingIdentifier] = useState<string | null>(null);
  const [unsavedPageIds, setUnsavedPageIds] = useState<string[]>([]);
  const [showCategoryDropdown, setShowCategoryDropdown] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState('');
  const categoryInputRef = useRef<HTMLDivElement>(null);

  const imageService = new ImageService(sessionToken, festivalId);

  // Get unique categories from existing pages
  const existingCategories = useMemo(() => {
    const categories = new Set(pages.map(page => page.category));
    return Array.from(categories).sort();
  }, [pages]);

  // Filter categories based on input
  const filteredCategories = useMemo(() => {
    if (!categoryFilter) return existingCategories;
    return existingCategories.filter(category => 
      category.toLowerCase().includes(categoryFilter.toLowerCase())
    );
  }, [existingCategories, categoryFilter]);

  useEffect(() => {
    const loadPages = async () => {
      try {
        const data = await fetchPages(sessionToken, festivalId);
        setPages(data);
      } catch (error) {
        console.error('Failed to load pages:', error);
      } finally {
        setLoading(false);
      }
    };
    loadPages();
  }, [sessionToken, festivalId]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (categoryInputRef.current && !categoryInputRef.current.contains(event.target as Node)) {
        setShowCategoryDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleAddPage = useCallback(() => {
    const newPage: Page = {
      identifier: uuidv4(),
      title: '[Title]',
      category: '',
      html_content: '',
      created_at: formatDateWithoutMilliseconds(new Date().toISOString()),
      updated_at: formatDateWithoutMilliseconds(new Date().toISOString()),
      order: 0,
      icon: null,
      header_image: null,
    };
    
    setUnsavedPageIds(prev => [...prev, newPage.identifier]);
    setPages(prevItems => [...prevItems, newPage]);
    setEditingPage(newPage);
  }, [pages.length]);

  const handleSavePage = useCallback(async (updatedPage: Page) => {
    try {
      setSavingIdentifier(updatedPage.identifier);
      setSaveError(null);
      
      let pageToSave = { ...updatedPage };

      if (pageToSave.icon) {
        const uploadedIcon = await imageService.uploadImageData(pageToSave.icon);
        pageToSave = {
          ...pageToSave,
          icon: uploadedIcon
        };
      }

      if (pageToSave.header_image) {
        const uploadedHeaderImage = await imageService.uploadImageData(pageToSave.header_image);
        pageToSave = {
          ...pageToSave,
          header_image: uploadedHeaderImage
        };
      }

      await updatePage(sessionToken, festivalId, pageToSave);
      setPages(prevItems => 
        prevItems.map(item => 
          item.identifier === pageToSave.identifier ? pageToSave : item
        )
      );
      setUnsavedPageIds(prev => prev.filter(id => id !== pageToSave.identifier));
      setEditingPage(null);
    } catch (error) {
      console.error('Failed to save page:', error);
      setSaveError(error instanceof Error ? error.message : 'Failed to save page');
    } finally {
      setSavingIdentifier(null);
    }
  }, [sessionToken, festivalId, imageService]);

  const handleRemovePage = useCallback(async (identifier: string) => {
    try {
      setDeletingIdentifier(identifier);
      await deletePage(sessionToken, festivalId, identifier);
      setPages(prevPages => prevPages.filter(page => page.identifier !== identifier));
      setEditingPage(null);
    } catch (error) {
      console.error('Failed to delete page:', error);
    } finally {
      setDeletingIdentifier(null);
    }
  }, [sessionToken, festivalId]);

  const handleEditPage = useCallback((page: Page) => {
    setEditingPage(editingPage?.identifier === page.identifier ? null : page);
  }, [editingPage]);

  const handleCancelEdit = useCallback((page: Page) => {
    setSaveError(null);
    if (unsavedPageIds.includes(page.identifier)) {
      setPages(prevItems => 
        prevItems.filter(item => item.identifier !== page.identifier)
      );
      setUnsavedPageIds(prev => prev.filter(id => id !== page.identifier));
    }
    setEditingPage(null);
  }, [unsavedPageIds]);

  const getMaxOrderForCategory = useCallback((category: string) => {
    return Math.max(
      -1, // Use -1 as base so if no pages exist, max + 1 = 0
      ...pages
        .filter(page => page.category === category)
        .map(page => page.order)
    );
  }, [pages]);

  const handleCategorySelect = (category: string) => {
    if (editingPage) {
      const newOrder = getMaxOrderForCategory(category) + 1;
      setEditingPage({
        ...editingPage,
        category,
        order: newOrder
      });
    }
    setShowCategoryDropdown(false);
    setCategoryFilter('');
  };

  const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newCategory = e.target.value;
    const newOrder = getMaxOrderForCategory(newCategory) + 1;
    setEditingPage({
      ...editingPage!,
      category: newCategory,
      order: newOrder
    });
    setCategoryFilter(newCategory);
    setShowCategoryDropdown(true);
  };

  const handleOrderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (editingPage) {
      const value = e.target.value;
      if (value === '' || /^\d+$/.test(value)) {
        setEditingPage({
          ...editingPage,
          order: value === '' ? 0 : parseInt(value, 10)
        });
      }
    }
  };

  const sortPages = (pages: Page[]) => {
    return [...pages].sort((a, b) => {
      if (a.category !== b.category) {
        return a.category.localeCompare(b.category);
      }
      return a.order - b.order;
    });
  };

  const handleIconChange = useCallback((newImage: ImageData | undefined) => {
    if (editingPage) {
      setEditingPage({
        ...editingPage,
        icon: newImage || null
      });
    }
  }, [editingPage]);

  const handleHeaderImageChange = useCallback((newImage: ImageData | undefined) => {
    if (editingPage) {
      setEditingPage({
        ...editingPage,
        header_image: newImage || null
      });
    }
  }, [editingPage]);

  if (loading) {
    return <div>Loading pages...</div>;
  }

  return (
    <section className="pages-editor">
      <h2>Pages</h2>
      <button onClick={handleAddPage} className="add-page-button">Add Page</button>
      <ul className="pages-list">
        {sortPages(pages).map((page) => (
          <li key={page.identifier} className="page-item">
            <div className="page-item-header" onClick={() => handleEditPage(page)}>
              <div className="page-item-preview">
                {page.icon ? (
                  <img 
                    src={getImageUrl(page.icon, getApiImageUrl)} 
                    alt={page.title}
                    className="page-icon"
                  />
                ) : (
                  <div className="page-icon-placeholder" />
                )}
                <div className="page-info">
                  <h4>{page.title}</h4>
                  <span className="page-category">{page.category}</span>
                </div>
              </div>
              <span>{new Date(page.updated_at).toLocaleDateString()}</span>
            </div>
            {editingPage?.identifier === page.identifier && (
              <div className="page-item-edit">
                {saveError && (
                  <div className="error-message">
                    {saveError}
                  </div>
                )}
                <div className="form-group">
                  <label className="form-label">
                    Title
                    <span className="form-label-hint">The title of the page</span>
                  </label>
                  <input
                    type="text"
                    value={editingPage.title}
                    onChange={(e) => setEditingPage({ ...editingPage, title: e.target.value })}
                    placeholder="Title"
                  />
                </div>
                <div className="form-row">
                  <div className="form-group form-group-half">
                    <label className="form-label">
                      Category
                      <span className="form-label-hint">Group pages by category</span>
                    </label>
                    <div className="category-input-container" ref={categoryInputRef}>
                      <input
                        type="text"
                        value={editingPage.category}
                        onChange={handleCategoryChange}
                        onFocus={() => setShowCategoryDropdown(true)}
                        placeholder="Category"
                      />
                      {showCategoryDropdown && (
                        <div className="category-dropdown">
                          {filteredCategories.length > 0 ? (
                            filteredCategories.map(category => (
                              <div
                                key={category}
                                className="category-option"
                                onClick={() => handleCategorySelect(category)}
                              >
                                {category}
                              </div>
                            ))
                          ) : (
                            <div className="category-option-empty">
                              Type to create a new category
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="form-group form-group-half">
                    <label className="form-label">
                      Order
                      <span className="form-label-hint">Display position within category section (lower numbers first)</span>
                    </label>
                    <input
                      type="text"
                      value={editingPage.order}
                      onChange={handleOrderChange}
                      placeholder="Order"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group form-group-half">
                    <label className="form-label">
                      Icon
                      <span className="form-label-hint">Small image shown in the list</span>
                    </label>
                    <ImageEditor
                      currentImage={editingPage.icon || undefined}
                      onChange={handleIconChange}
                      getApiImageUrl={getApiImageUrl}
                    />
                  </div>
                  <div className="form-group form-group-half">
                    <label className="form-label">
                      Header Image
                      <span className="form-label-hint">Large image shown at the top of the page</span>
                    </label>
                    <ImageEditor
                      currentImage={editingPage.header_image || undefined}
                      onChange={handleHeaderImageChange}
                      getApiImageUrl={getApiImageUrl}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="form-label">
                    Content
                    <span className="form-label-hint">HTML content of the page</span>
                  </label>
                  <textarea
                    value={editingPage.html_content}
                    onChange={(e) => setEditingPage({ ...editingPage, html_content: e.target.value })}
                    placeholder="HTML Content"
                  />
                </div>
                <div className="page-item-actions">
                  <button 
                    onClick={() => handleSavePage(editingPage)}
                    disabled={savingIdentifier === page.identifier}
                  >
                    {savingIdentifier === page.identifier ? 'Saving...' : 'Save'}
                  </button>
                  <button onClick={() => handleCancelEdit(page)}>Cancel</button>
                  {!unsavedPageIds.includes(page.identifier) && (
                    <button 
                      onClick={() => handleRemovePage(page.identifier)}
                      disabled={deletingIdentifier === page.identifier}
                    >
                      {deletingIdentifier === page.identifier ? 'Removing...' : 'Remove'}
                    </button>
                  )}
                </div>
              </div>
            )}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default PagesEditor; 