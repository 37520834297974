import React, { useState, useRef, useEffect } from 'react';
import { Show, Artist } from '../../../../model/FestivalData';
import { sendNotification, CustomNotificationTopic, getTopics, upsertTopic, getSubdomainFromFestivalId } from '../../../../services/api';
import { generateCanonicalName, generateIdentifier } from '../../../../utils/identifiers';
import './NotificationsEditor.css';
import './ShareLinkModal.css';

interface NotificationsEditorProps {
  shows: Show[];
  artists: Artist[];
  sessionToken: string;
  festivalId: string;
}

interface NotificationForm {
  topic_identifier: string;
  title: string;
  body: string;
}

interface DropdownOption {
  value: string;
  label: string;
  group?: string;
}

interface DropdownSection {
  label: string;
  options: DropdownOption[];
}

const all_users_push_topic = 'all_users';

const NotificationsEditor: React.FC<NotificationsEditorProps> = ({
  shows,
  artists,
  sessionToken,
  festivalId,
}) => {
  const [form, setForm] = useState<NotificationForm>({
    topic_identifier: '',
    title: '',
    body: '',
  });
  const [showModal, setShowModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [recipientFilter, setRecipientFilter] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [customTopics, setCustomTopics] = useState<CustomNotificationTopic[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editingTopic, setEditingTopic] = useState<CustomNotificationTopic | null>(null);
  const [newTopicName, setNewTopicName] = useState('');
  const [copySuccess, setCopySuccess] = useState<string | null>(null);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [currentLink, setCurrentLink] = useState('');

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const fetchTopics = async () => {
      try {
        const topics = await getTopics(sessionToken, festivalId);
        setCustomTopics(topics);
      } catch (error) {
        console.error('Failed to fetch topics:', error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchTopics();
  }, [sessionToken, festivalId]);

  const getArtistName = (artistId: string): string => {
    return artists.find(artist => artist.identifier === artistId)?.name || 'Unknown Artist';
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleSend = async () => {
    try {
      await sendNotification(sessionToken, festivalId, {
        topic: form.topic_identifier === all_users_push_topic || customTopics.some(t => t.identifier === form.topic_identifier)
          ? form.topic_identifier 
          : `show_${form.topic_identifier}`,
        title: form.title,
        body: form.body,
      });
      
      setShowModal(false);
      setIsSuccess(true);
      setForm({ topic_identifier: '', title: '', body: '' });
      
      // Hide success message after 3 seconds
      setTimeout(() => {
        setIsSuccess(false);
      }, 3000);
    } catch (error) {
      console.error('Failed to send notification:', error);
      alert('Failed to send notification. Please try again.');
    }
  };

  const handleCreateTopic = async () => {
    const canonicalName = generateCanonicalName(recipientFilter);
    const newTopic: CustomNotificationTopic = {
      identifier: generateIdentifier(canonicalName),
      name: recipientFilter,
    };

    try {
      await upsertTopic(sessionToken, festivalId, newTopic);
      setCustomTopics([...customTopics, newTopic]);
      setForm({ ...form, topic_identifier: newTopic.identifier });
      setIsDropdownOpen(false);
      setRecipientFilter('');
    } catch (error) {
      console.error('Failed to create topic:', error);
      alert('Failed to create custom topic. Please try again.');
    }
  };

  const handleUpdateTopic = async () => {
    if (!editingTopic || !newTopicName.trim()) return;
    
    try {
      const updatedTopic = {
        ...editingTopic,
        name: newTopicName.trim()
      };
      
      await upsertTopic(sessionToken, festivalId, updatedTopic);
      
      setCustomTopics(prevTopics => 
        prevTopics.map(topic => 
          topic.identifier === editingTopic.identifier ? updatedTopic : topic
        )
      );
      
      setEditingTopic(null);
      setNewTopicName('');
    } catch (error) {
      console.error('Failed to update topic:', error);
      alert('Failed to update topic name. Please try again.');
    }
  };

  const handleCopyLink = async (topicIdentifier: string) => {
    try {
      // Get hostname and check if we're on localhost
      const hostname = window.location.hostname;
      let subdomain = hostname.split('.')[0];
      
      if (hostname === 'localhost' || hostname === '127.0.0.1' || subdomain === hostname) {
        // If on localhost, fetch subdomain from API
        subdomain = await getSubdomainFromFestivalId(festivalId);
      }

      const subscribeLink = `https://api.dev.festival.systems/${subdomain}/deeplink/notification/subscribe/${topicIdentifier}`;
      const topic = customTopics.find(t => t.identifier === topicIdentifier);
      setCurrentLink(subscribeLink);
      setNewTopicName(topic?.name || '');
      setShowLinkModal(true);
    } catch (error) {
      console.error('Failed to get link:', error);
      setCopySuccess('Failed to generate link');
      setTimeout(() => setCopySuccess(null), 3000);
    }
  };

  const attemptCopy = async () => {
    try {
      await navigator.clipboard.writeText(currentLink);
      setCopySuccess('Link copied to clipboard!');
    } catch (error) {
      console.error('Copy failed:', error);
      setCopySuccess('Please select and copy the link manually');
    }
    setTimeout(() => setCopySuccess(null), 3000);
  };

  const sections: DropdownSection[] = [
    {
      label: "General",
      options: [
        ...(customTopics.some(t => t.identifier === all_users_push_topic) 
          ? []
          : [{
              value: all_users_push_topic,
              label: 'All Users',
            }]
        ),
        ...customTopics
          .map(topic => ({
            value: topic.identifier,
            label: topic.identifier === all_users_push_topic 
              ? topic.name 
              : `${topic.name} (code=${topic.identifier})`
          }))
      ]
    },
    {
      label: "Show Subscribers",
      options: shows.map(show => ({
        value: show.identifier,
        label: getArtistName(show.artist_id)
      }))
    }
  ];

  const filteredSections = sections.map(section => ({
    ...section,
    options: section.options.filter(option =>
      option.label.toLowerCase().includes(recipientFilter.toLowerCase())
    )
  })).filter(section => section.options.length > 0);

  const selectedOption = sections
    .flatMap(section => section.options)
    .find(opt => opt.value === form.topic_identifier) || { value: '', label: 'Select recipients' };

  // Add keyboard navigation
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsDropdownOpen(false);
    }
  };

  // Prevent dropdown close when clicking filter
  const handleFilterClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  // Focus filter input when dropdown opens
  useEffect(() => {
    if (isDropdownOpen) {
      const filterInput = document.querySelector('.recipient-filter') as HTMLInputElement;
      if (filterInput) {
        filterInput.focus();
      }
    }
  }, [isDropdownOpen]);

  return (
    <div className="notifications-editor">
      <h2>Send Notifications</h2>
      
      {isSuccess && (
        <div className="success-alert">
          Notification sent successfully!
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="topic_identifier">Recipients</label>
          <div className="custom-dropdown" ref={dropdownRef}>
            <div 
              className="dropdown-header" 
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              role="combobox"
              aria-expanded={isDropdownOpen}
              aria-haspopup="listbox"
              tabIndex={0}
              onKeyDown={handleKeyDown}
            >
              <span>{selectedOption.label}</span>
              <span className={`dropdown-arrow ${isDropdownOpen ? 'open' : ''}`}>▼</span>
            </div>
            
            {isDropdownOpen && (
              <div className="dropdown-content">
                <div className="filter-container">
                  <input
                    type="text"
                    placeholder="Type to search recipients..."
                    value={recipientFilter}
                    onChange={(e) => setRecipientFilter(e.target.value)}
                    className="recipient-filter"
                    onClick={handleFilterClick}
                    onKeyDown={handleKeyDown}
                  />
                </div>
                <div 
                  className="dropdown-options"
                  role="listbox"
                >
                  {filteredSections.map((section, index) => (
                    <div key={section.label} className="dropdown-section">
                      <div className="dropdown-section-header">
                        {section.label}
                        <span className="section-count">
                          {section.options.length}
                        </span>
                      </div>
                      {section.options.map((option) => (
                        <div
                          key={option.value}
                          className={`dropdown-option ${form.topic_identifier === option.value ? 'selected' : ''}`}
                        >
                          <div
                            className="dropdown-option-content"
                            onClick={() => {
                              setForm({ ...form, topic_identifier: option.value });
                              setIsDropdownOpen(false);
                              setRecipientFilter('');
                            }}
                            role="option"
                            aria-selected={form.topic_identifier === option.value}
                          >
                            {option.label}
                          </div>
                          {(customTopics.some(t => t.identifier === option.value) || 
                            option.value === all_users_push_topic) && (
                            <div className="topic-actions">
                              {option.value !== all_users_push_topic && (
                                <button
                                  className="edit-topic-button"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    handleCopyLink(option.value);
                                  }}
                                  title="Copy subscription link"
                                >
                                  🔗
                                </button>
                              )}
                              <button
                                className="edit-topic-button"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  const topic = customTopics.find(t => t.identifier === option.value) || 
                                    (option.value === all_users_push_topic 
                                      ? { identifier: all_users_push_topic, name: 'All Users' }
                                      : null);
                                  if (topic) {
                                    setEditingTopic(topic);
                                    setNewTopicName(topic.name);
                                  }
                                }}
                              >
                                ✎
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  ))}
                  {filteredSections.length === 0 && (
                    <div className="dropdown-no-results">
                      <div>No recipients match "{recipientFilter}"</div>
                      <button
                        className="create-topic-button"
                        onClick={handleCreateTopic}
                      >
                        Create custom topic "{recipientFilter}"
                      </button>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            value={form.title}
            onChange={(e) => setForm({ ...form, title: e.target.value })}
            required
            maxLength={50}
          />
        </div>

        <div className="form-group">
          <label htmlFor="body">Message</label>
          <textarea
            id="body"
            value={form.body}
            onChange={(e) => setForm({ ...form, body: e.target.value })}
            required
            maxLength={200}
            rows={4}
          />
        </div>

        <button 
          type="submit" 
          className="send-button"
          disabled={!form.topic_identifier}
        >
          Send Notification
        </button>
      </form>

      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Confirm Notification</h3>
            <div className="notification-preview">
              <p><strong>Recipients:</strong> {
                form.topic_identifier === all_users_push_topic 
                  ? (customTopics.find(t => t.identifier === all_users_push_topic)?.name || 'All Users')
                  : shows.find(s => s.identifier === form.topic_identifier)
                    ? `Subscribers of ${getArtistName(shows.find(s => s.identifier === form.topic_identifier)?.artist_id || '')}`
                    : customTopics.find(t => t.identifier === form.topic_identifier)?.name || 'Unknown'
              }</p>
              <p><strong>Title:</strong> {form.title}</p>
              <p><strong>Message:</strong> {form.body}</p>
            </div>
            <div className="modal-actions">
              <button onClick={handleSend} className="primary">
                Send
              </button>
              <button onClick={() => setShowModal(false)} className="secondary">
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {editingTopic && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Edit Topic Name</h3>
            <div className="form-group">
              <label htmlFor="topicName">Topic Name</label>
              <input
                type="text"
                id="topicName"
                value={newTopicName}
                onChange={(e) => setNewTopicName(e.target.value)}
                required
                maxLength={50}
              />
            </div>
            <div className="modal-actions">
              <button onClick={handleUpdateTopic} className="primary">
                Save
              </button>
              <button 
                onClick={() => {
                  setEditingTopic(null);
                  setNewTopicName('');
                }} 
                className="secondary"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {showLinkModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h3>Share Subscription Link</h3>
            <p className="modal-description">
              Share this link to users of group "{newTopicName}". They will be automatically subscribed to these notifications.
            </p>
            <div className="link-container">
              <input
                type="text"
                value={currentLink}
                readOnly
                onClick={(e) => e.currentTarget.select()}
                className="link-input"
              />
              <button onClick={attemptCopy} className="copy-button">
                Copy
              </button>
            </div>
            <div className="modal-actions">
              <button onClick={() => setShowLinkModal(false)} className="secondary">
                Close
              </button>
            </div>
          </div>
        </div>
      )}

      {copySuccess && (
        <div className="success-message">
          {copySuccess}
        </div>
      )}
    </div>
  );
};

export default NotificationsEditor; 