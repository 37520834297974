import React, { useState, useEffect, useRef } from 'react';
import { fetchUsers, updateUserRoles, createUser, deleteUser, resetUserPassword } from '../../../services/api';
import './UserEditor.css';
import { Role, SETTABLE_ROLES } from '../../../model/Roles';
import Modal from '../../../components/Modal';

interface User {
  user_id: string;
  email: string;
  roles: string[];
}

interface UserEditorProps {
  sessionToken: string;
  festivalId: string;
  currentUser: User;
}

interface DropdownProps {
  options: typeof SETTABLE_ROLES;
  value: string[];
  onChange: (newValue: string[]) => void;
  disabled?: boolean;
}

const RolesDropdown: React.FC<DropdownProps> = ({ options, value, onChange, disabled }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const toggleRole = (roleValue: string) => {
    let newRoles: string[];
    
    if (roleValue === Role.ADMIN) {
      // If selecting admin, clear all other roles
      newRoles = value.includes(Role.ADMIN) ? [] : [Role.ADMIN];
    } else {
      // If selecting another role, remove admin if present and toggle the selected role
      newRoles = value.filter(r => r !== Role.ADMIN);
      if (value.includes(roleValue)) {
        newRoles = newRoles.filter(r => r !== roleValue);
      } else {
        newRoles.push(roleValue);
      }
    }
    
    onChange(newRoles);
  };

  return (
    <div className="roles-dropdown" ref={dropdownRef}>
      <div 
        className={`dropdown-header ${disabled ? 'disabled' : ''}`}
        onClick={() => !disabled && setIsOpen(!isOpen)}
      >
        <div className="selected-roles">
          {value.length === 0 ? (
            <span className="placeholder">Select roles...</span>
          ) : (
            <div className="role-tags">
              {value.map(role => (
                <span key={role} className="role-tag">
                  {options.find(opt => opt.value === role)?.label || role}
                </span>
              ))}
            </div>
          )}
        </div>
        <span className="dropdown-arrow">{isOpen ? '▲' : '▼'}</span>
      </div>
      {isOpen && !disabled && (
        <div className="dropdown-options">
          {options.map(option => (
            <label key={option.value} className="dropdown-option">
              <input
                type="checkbox"
                checked={value.includes(option.value)}
                onChange={() => toggleRole(option.value)}
              />
              <span>{option.label}</span>
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

interface NewUserModalProps {
  onClose: () => void;
  onSubmit: (email: string, roles: string[]) => Promise<void>;
}

const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const NewUserModal: React.FC<NewUserModalProps> = ({ onClose, onSubmit }) => {
  const [email, setEmail] = useState('');
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    email: 'Email is required',
    roles: 'At least one role must be selected'
  });

  const validateEmail = (email: string): string => {
    if (!email) return 'Email is required';
    if (!isValidEmail(email)) return 'Please enter a valid email address';
    return '';
  };

  const validateRoles = (roles: string[]): string => {
    if (roles.length === 0) return 'At least one role must be selected';
    return '';
  };

  const validateForm = (): boolean => {
    const emailError = validateEmail(email);
    const rolesError = validateRoles(selectedRoles);

    setValidationErrors({
      email: emailError,
      roles: rolesError
    });

    return !emailError && !rolesError;
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setValidationErrors(prev => ({
      ...prev,
      email: validateEmail(newEmail)
    }));
  };

  const handleRolesChange = (newRoles: string[]) => {
    setSelectedRoles(newRoles);
    setValidationErrors(prev => ({
      ...prev,
      roles: validateRoles(newRoles)
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
    
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    
    try {
      await onSubmit(email, selectedRoles);
      onClose();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to create user');
    } finally {
      setIsSubmitting(false);
    }
  };

  const isFormValid = !validationErrors.email && !validationErrors.roles;

  return (
    <div className="modal-overlay" onClick={() => !isSubmitting && onClose()}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <h3>Create New User</h3>
          {!isSubmitting && (
            <button className="modal-close" onClick={onClose}>×</button>
          )}
        </div>
        {error && <div className="modal-error">{error}</div>}
        <form onSubmit={handleSubmit} className="modal-form">
          <div className="modal-form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={handleEmailChange}
              className={validationErrors.email ? 'error' : ''}
              required
              disabled={isSubmitting}
            />
            {validationErrors.email && (
              <div className="input-error">{validationErrors.email}</div>
            )}
          </div>
          <div className="modal-form-group">
            <label>Roles</label>
            <RolesDropdown
              options={SETTABLE_ROLES}
              value={selectedRoles}
              onChange={handleRolesChange}
              disabled={isSubmitting}
            />
            {validationErrors.roles && (
              <div className="input-error">{validationErrors.roles}</div>
            )}
          </div>
          <div className="modal-actions">
            <button 
              type="submit" 
              className="modal-submit"
              disabled={isSubmitting || !isFormValid}
            >
              {isSubmitting ? (
                <span className="button-content">
                  <span className="spinner"></span>
                  Creating...
                </span>
              ) : (
                'Create User'
              )}
            </button>
            <button 
              type="button" 
              className="modal-cancel"
              onClick={onClose}
              disabled={isSubmitting}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

interface DeleteConfirmModalProps {
  user: User;
  onConfirm: () => Promise<void>;
  onCancel: () => void;
}

const DeleteConfirmModal: React.FC<DeleteConfirmModalProps> = ({ user, onConfirm, onCancel }) => {
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleDelete = async () => {
    setIsDeleting(true);
    setError(null);
    try {
      await onConfirm();
      onCancel();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to delete user');
      setIsDeleting(false);
    }
  };

  return (
    <div className="modal-overlay" onClick={() => !isDeleting && onCancel()}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <div className="modal-header">
          <h3>Delete User</h3>
          {!isDeleting && (
            <button className="modal-close" onClick={onCancel}>×</button>
          )}
        </div>
        {error && <div className="modal-error">{error}</div>}
        <p>This will delete user {user.email}. Are you sure?</p>
        <div className="modal-actions">
          <button 
            className="modal-delete"
            onClick={handleDelete}
            disabled={isDeleting}
          >
            {isDeleting ? (
              <span className="button-content">
                <span className="spinner"></span>
                Deleting...
              </span>
            ) : (
              'Delete'
            )}
          </button>
          <button 
            className="modal-cancel"
            onClick={onCancel}
            disabled={isDeleting}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

interface ResetPasswordModalProps {
  user: User;
  onConfirm: () => Promise<void>;
  onCancel: () => void;
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = ({ user, onConfirm, onCancel }) => {
  const [isResetting, setIsResetting] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleReset = async () => {
    setIsResetting(true);
    setError(null);
    try {
      await onConfirm();
      onCancel();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to reset password');
      setIsResetting(false);
    }
  };

  return (
    <Modal isOpen={true} onClose={() => !isResetting && onCancel()}>
      <div className="modal-header">
        <h3>Reset Password</h3>
        {!isResetting && (
          <button className="modal-close" onClick={onCancel}>×</button>
        )}
      </div>
      {error && <div className="modal-error">{error}</div>}
      <p>A new password will be sent to {user.email}. Continue?</p>
      <div className="modal-actions">
        <button 
          className="modal-submit"
          onClick={handleReset}
          disabled={isResetting}
        >
          {isResetting ? (
            <span className="button-content">
              <span className="spinner"></span>
              Resetting...
            </span>
          ) : (
            'Reset Password'
          )}
        </button>
        <button 
          className="modal-cancel"
          onClick={onCancel}
          disabled={isResetting}
        >
          Cancel
        </button>
      </div>
    </Modal>
  );
};

const UserEditor: React.FC<UserEditorProps> = ({ sessionToken, festivalId, currentUser }) => {
  const [users, setUsers] = useState<User[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [editingRoles, setEditingRoles] = useState<{ [key: string]: string[] }>({});
  const [updatingUser, setUpdatingUser] = useState<string | null>(null);
  const [showNewUserModal, setShowNewUserModal] = useState(false);
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const [userToResetPassword, setUserToResetPassword] = useState<User | null>(null);

  useEffect(() => {
    loadUsers();
  }, [sessionToken, festivalId]);

  const loadUsers = async () => {
    try {
      const fetchedUsers = await fetchUsers(sessionToken, festivalId);
      setUsers(fetchedUsers);
      const initialEditingRoles: { [key: string]: string[] } = {};
      fetchedUsers.forEach(user => {
        initialEditingRoles[user.user_id] = [...(user.roles || [])];
      });
      setEditingRoles(initialEditingRoles);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to load users');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateRoles = async (userId: string) => {
    setUpdatingUser(userId);
    try {
      await updateUserRoles(sessionToken, festivalId, userId, editingRoles[userId]);
      await loadUsers();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to update roles');
    } finally {
      setUpdatingUser(null);
    }
  };

  const handleCreateUser = async (email: string, roles: string[]) => {
    await createUser(sessionToken, festivalId, email, roles);
    await loadUsers(); // Refresh the users list
  };

  const handleDeleteUser = async (userId: string) => {
    try {
      await deleteUser(sessionToken, festivalId, userId);
      await loadUsers(); // Refresh the users list
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to delete user');
    }
  };

  const handleResetPassword = async (userId: string) => {
    try {
      await resetUserPassword(sessionToken, festivalId, userId);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'Failed to reset password');
    }
  };

  const isRolesValid = (roles: string[]): boolean => {
    return roles.length > 0;
  };

  if (loading) {
    return <div className="user-editor-loading">Loading users...</div>;
  }

  if (error) {
    return <div className="user-editor-error">{error}</div>;
  }

  return (
    <div className="user-editor">
      <div className="user-editor-header">
        <h2>Users</h2>
        <button 
          className="new-user-button"
          onClick={() => setShowNewUserModal(true)}
        >
          New User
        </button>
      </div>
      <div className="user-table-container">
        <table className="user-table">
          <thead>
            <tr>
              <th>Email</th>
              <th>Roles</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user) => (
              <tr key={user.user_id} className={user.user_id === currentUser.user_id ? 'current-user' : ''}>
                <td>{user.email}</td>
                <td>
                  {user.user_id !== currentUser.user_id ? (
                    <div className="role-editor">
                      <RolesDropdown
                        options={SETTABLE_ROLES}
                        value={editingRoles[user.user_id] || []}
                        onChange={(newRoles) => setEditingRoles(prev => ({
                          ...prev,
                          [user.user_id]: newRoles
                        }))}
                      />
                      <button
                        className="update-roles-button"
                        onClick={() => handleUpdateRoles(user.user_id)}
                        disabled={
                          updatingUser === user.user_id || 
                          !isRolesValid(editingRoles[user.user_id] || [])
                        }
                      >
                        {updatingUser === user.user_id ? 'Updating...' : 'Update Roles'}
                      </button>
                    </div>
                  ) : (
                    <div className="current-user-roles">
                      {user.roles?.map((role) => (
                        <div key={role} className="current-user-role">
                          {SETTABLE_ROLES.find(r => r.value === role)?.label || role}
                        </div>
                      )) || <div className="current-user-role">No roles</div>}
                    </div>
                  )}
                </td>
                <td>
                  {user.user_id === currentUser.user_id ? (
                    <span className="no-actions">No actions</span>
                  ) : (
                    <div className="user-actions">
                      <button
                        className="reset-password-button"
                        onClick={() => setUserToResetPassword(user)}
                      >
                        Reset Password
                      </button>
                      <button
                        className="delete-button"
                        onClick={() => setUserToDelete(user)}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showNewUserModal && (
        <NewUserModal
          onClose={() => setShowNewUserModal(false)}
          onSubmit={handleCreateUser}
        />
      )}
      {userToDelete && (
        <DeleteConfirmModal
          user={userToDelete}
          onConfirm={() => handleDeleteUser(userToDelete.user_id)}
          onCancel={() => setUserToDelete(null)}
        />
      )}
      {userToResetPassword && (
        <ResetPasswordModal
          user={userToResetPassword}
          onConfirm={() => handleResetPassword(userToResetPassword.user_id)}
          onCancel={() => setUserToResetPassword(null)}
        />
      )}
    </div>
  );
};

export default UserEditor; 