import React from 'react';
import { PUBLISHED_STATUS } from '../../../../model/FestivalData';
import SaveButtonsContainer from '../SaveButtonsContainer/SaveButtonsContainer';
import './DeferrableSavingComponent.css';

interface DeferrableSavingComponentProps {
  children: React.ReactNode;
  unsavedChanges: Set<string>;
  lastSaveTime: Date | null;
  isSaving: boolean;
  onSave: () => void;
  status?: PUBLISHED_STATUS;
  onStatusChange?: (newStatus: PUBLISHED_STATUS) => void;
}

const DeferrableSavingComponent: React.FC<DeferrableSavingComponentProps> = ({
  children,
  unsavedChanges,
  lastSaveTime,
  isSaving,
  onSave,
  status,
  onStatusChange
}) => {
  return (
    <div className="deferrable-saving-component">
      <div className="deferrable-saving-header">
        <SaveButtonsContainer
          unsavedChanges={unsavedChanges}
          lastSaveTime={lastSaveTime}
          isSaving={isSaving}
          onSave={onSave}
          status={status}
          onStatusChange={onStatusChange}
        />
      </div>
      <div className="deferrable-saving-content">
        {children}
      </div>
    </div>
  );
};

export default DeferrableSavingComponent; 