import React from 'react';

interface ThemeSectionGroupProps {
  title: string;
  children: React.ReactNode;
}

const ThemeSectionGroup: React.FC<ThemeSectionGroupProps> = ({ title, children }) => (
  <div className="theme-section-group">
    <h4>{title}</h4>
    {children}
  </div>
);

export default ThemeSectionGroup; 