import React from 'react';
import { BACKGROUND_TYPE, FestivalData, ImageData } from '../../../../model/FestivalData';
import ImageEditor from '../ImageEditor/ImageEditor';
import ColorInput from './inputs/ColorInput';
import NumberInput from './inputs/NumberInput';
import ThemeSection from './sections/ThemeSection';
import ThemeSectionGroup from './sections/ThemeSectionGroup';
import ImageEditorsGroup from './sections/ImageEditorsGroup';
import './InfosEditor.css';

interface InfosEditorProps {
  infos: FestivalData['infos'];
  onChange: (newInfos: FestivalData['infos']) => void;
  getApiImageUrl: (identifier: string) => string;
}

const InfosEditor: React.FC<InfosEditorProps> = ({ infos, onChange, getApiImageUrl }) => {
  type StylesheetType = FestivalData['infos']['stylesheet'];
  type StylesheetUpdate = { [K in keyof StylesheetType]?: Partial<StylesheetType[K]> };

  // Helper functions for state updates
  const createNumberChangeHandler = (
    path: string[],
    minValue: number = 0
  ) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Math.max(minValue, parseInt(e.target.value) || 0);
    const newInfos = { ...infos };
    let current: any = newInfos;
    for (let i = 0; i < path.length - 1; i++) {
      current = current[path[i]];
    }
    current[path[path.length - 1]] = value;
    onChange(newInfos);
  };

  const createColorChangeHandler = (
    path: string[]
  ) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const newInfos = { ...infos };
    let current: any = newInfos;
    for (let i = 0; i < path.length - 1; i++) {
      current = current[path[i]];
    }
    current[path[path.length - 1]] = e.target.value;
    onChange(newInfos);
  };

  // Basic handlers
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    onChange({
      ...infos,
      [name]: value
    });
  };

  const handleLogoChange = (newImage: ImageData | undefined) => {
    onChange({
      ...infos,
      festival_logo: newImage || null
    });
  };

  const handleTicketImageChange = (newImage: ImageData | undefined) => {
    onChange({
      ...infos,
      tickets: {
        description: infos.tickets?.description || '',
        link: infos.tickets?.link || '',
        image: newImage || null
      }
    });
  };

  const handleNewsHeaderImageChange = (newImage: ImageData | undefined) => {
    onChange({
      ...infos,
      news_header_image: newImage || null
    });
  };

  const handleThemeChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    onChange({
      ...infos,
      stylesheet: {
        ...infos.stylesheet,
        [name]: value
      }
    });
  };

  const handleBackgroundTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newType = e.target.value as BACKGROUND_TYPE;
    onChange({
      ...infos,
      stylesheet: {
        ...infos.stylesheet,
        background: {
          type: newType,
          primary_color: infos.stylesheet.background.primary_color || '#ffffff',
          secondary_color: newType === 'Gradient' ? (infos.stylesheet.background.secondary_color || '#ffffff') : undefined,
          image: newType === 'Image' ? infos.stylesheet.background.image : null,
        }
      }
    });
  };

  const handleBackgroundImageChange = (newImage: ImageData | undefined) => {
    onChange({
      ...infos,
      stylesheet: {
        ...infos.stylesheet,
        background: {
          ...infos.stylesheet.background,
          image: newImage || null
        }
      }
    });
  };

  // Use helper functions for all the repetitive handlers
  const handleShowCellHeightChange = createNumberChangeHandler(['stylesheet', 'show', 'cell_height'], 40);
  const handleNewsCellHeightChange = createNumberChangeHandler(['stylesheet', 'news', 'cell_height'], 60);
  const handleNewsPaddingChange = createNumberChangeHandler(['stylesheet', 'news', 'padding']);
  const handleNewsHeaderHeightChange = createNumberChangeHandler(['stylesheet', 'news', 'header_height']);
  const handleNewsItemsSpacingChange = createNumberChangeHandler(['stylesheet', 'news', 'items_spacing']);
  const handlePagesIconHeightChange = createNumberChangeHandler(['stylesheet', 'pages', 'icon_height']);
  const handlePagesHeaderHeightChange = createNumberChangeHandler(['stylesheet', 'pages', 'header_height']);
  const handleNotificationsPaddingChange = createNumberChangeHandler(['stylesheet', 'notifications', 'padding']);
  const handleNotificationsItemsSpacingChange = createNumberChangeHandler(['stylesheet', 'notifications', 'items_spacing']);
  const handleNotificationsVerticalSpacingChange = createNumberChangeHandler(['stylesheet', 'notifications', 'items_vertical_spacing']);
  const handleNavBarLogoHeightChange = createNumberChangeHandler(['stylesheet', 'nav_bar_logo_height']);
  const handleShowDetailsHeaderHeightChange = createNumberChangeHandler(['stylesheet', 'show', 'details_header_height']);
  const handleNewsDetailsHeaderHeightChange = createNumberChangeHandler(['stylesheet', 'news', 'details_header_height']);
  const handlePageRowHeightChange = createNumberChangeHandler(['stylesheet', 'pages', 'row_height']);
  const handlePageSectionHeightChange = createNumberChangeHandler(['stylesheet', 'pages', 'section_height']);

  const handleNewsItemsBackgroundColorChange = createColorChangeHandler(['stylesheet', 'news', 'items_background_color']);
  const handleNewsItemsTitleColorChange = createColorChangeHandler(['stylesheet', 'news', 'items_title_color']);
  const handleNewsItemsSubtitleColorChange = createColorChangeHandler(['stylesheet', 'news', 'items_subtitle_color']);
  const handleNewsItemsDateColorChange = createColorChangeHandler(['stylesheet', 'news', 'items_date_color']);
  const handleShowItemsArtistNameColorChange = createColorChangeHandler(['stylesheet', 'show', 'items_artist_name_color']);
  const handleShowItemsSceneNameColorChange = createColorChangeHandler(['stylesheet', 'show', 'items_scene_name_color']);
  const handleShowItemsShowDateColorChange = createColorChangeHandler(['stylesheet', 'show', 'items_show_date_color']);
  const handlePageTitleColorChange = createColorChangeHandler(['stylesheet', 'pages', 'title_color']);
  const handlePageSectionColorChange = createColorChangeHandler(['stylesheet', 'pages', 'section_color']);
  const handleNewsTitleColorChange = createColorChangeHandler(['stylesheet', 'news', 'title_color']);

  return (
    <section className="infos-editor">
      <h2>Infos and Layout</h2>
      
      <ThemeSection title="Festival">
        <ThemeSectionGroup title="">
          <div className="form-group">
            <label htmlFor="festival_name">Festival Name:</label>
            <input
              id="festival_name"
              type="text"
              name="festival_name"
              value={infos.festival_name}
              onChange={handleChange}
            />
            <span className="field-description">The official name of the festival.</span>
          </div>
        </ThemeSectionGroup>
      </ThemeSection>

      <ThemeSection title="General">
        <ThemeSectionGroup title="Navigation Bar">
          <NumberInput
            id="nav_bar_logo_height"
            name="nav_bar_logo_height"
            value={infos.stylesheet.nav_bar_logo_height}
            onChange={handleNavBarLogoHeightChange}
            label="Logo Height (px)"
            description="Height of the logo in the navigation bar"
          />
        </ThemeSectionGroup>

        <ThemeSectionGroup title="Text Colors">
          <ColorInput
            id="title_color"
            name="title_color"
            value={infos.stylesheet.title_color}
            onChange={handleThemeChange}
            label="Title Color"
          />
          <ColorInput
            id="caption_color"
            name="caption_color"
            value={infos.stylesheet.caption_color}
            onChange={handleThemeChange}
            label="Caption Color"
            placeholder="#666666"
          />
          <ColorInput
            id="body_color"
            name="body_color"
            value={infos.stylesheet.body_color}
            onChange={handleThemeChange}
            label="Body Text Color"
            placeholder="#333333"
          />
        </ThemeSectionGroup>

        <ThemeSectionGroup title="Background">
          <div className="form-group">
            <label htmlFor="background_type">Background Type:</label>
            <select
              id="background_type"
              value={infos.stylesheet.background.type}
              onChange={handleBackgroundTypeChange}
            >
              <option value="Color">Solid Color</option>
              <option value="Gradient">Gradient</option>
              <option value="Image">Image</option>
            </select>
          </div>

          {(infos.stylesheet.background.type === 'Color' || infos.stylesheet.background.type === 'Gradient') && (
            <ColorInput
              id="primary_color"
              name="primary_color"
              value={infos.stylesheet.background.primary_color || ''}
              onChange={(e) => onChange({
                ...infos,
                stylesheet: {
                  ...infos.stylesheet,
                  background: {
                    ...infos.stylesheet.background,
                    primary_color: e.target.value,
                  }
                }
              })}
              label="Primary Color"
            />
          )}

          {infos.stylesheet.background.type === 'Gradient' && (
            <ColorInput
              id="secondary_color"
              name="secondary_color"
              value={infos.stylesheet.background.secondary_color || ''}
              onChange={(e) => onChange({
                ...infos,
                stylesheet: {
                  ...infos.stylesheet,
                  background: {
                    ...infos.stylesheet.background,
                    secondary_color: e.target.value,
                  }
                }
              })}
              label="Secondary Color"
            />
          )}

          {infos.stylesheet.background.type === 'Image' && (
            <div className="form-group">
              <label>Background Image:</label>
              <ImageEditor
                currentImage={infos.stylesheet.background.image || undefined}
                onChange={handleBackgroundImageChange}
                getApiImageUrl={getApiImageUrl}
                fillMode="cover"
              />
            </div>
          )}
        </ThemeSectionGroup>
      </ThemeSection>

      <ThemeSection title="Sections">
        <ThemeSectionGroup title="Shows">
          <NumberInput
            id="show_cell_height"
            name="show_cell_height"
            value={infos.stylesheet.show.cell_height}
            onChange={handleShowCellHeightChange}
            min={40}
            label="Cell Height (px)"
            description="Minimum height for show items (min: 40px)"
          />
          <NumberInput
            id="show_details_header_height"
            name="show_details_header_height"
            value={infos.stylesheet.show.details_header_height}
            onChange={handleShowDetailsHeaderHeightChange}
            label="Details Header Height (px)"
            description="Height of the header in details view"
          />
          <ColorInput
            id="show_items_artist_name_color"
            name="show_items_artist_name_color"
            value={infos.stylesheet.show.items_artist_name_color}
            onChange={handleShowItemsArtistNameColorChange}
            label="Artist Name Color"
          />
          <ColorInput
            id="show_items_scene_name_color"
            name="show_items_scene_name_color"
            value={infos.stylesheet.show.items_scene_name_color}
            onChange={handleShowItemsSceneNameColorChange}
            label="Scene Name Color"
            placeholder="#666666"
          />
          <ColorInput
            id="show_items_show_date_color"
            name="show_items_show_date_color"
            value={infos.stylesheet.show.items_show_date_color}
            onChange={handleShowItemsShowDateColorChange}
            label="Show Date Color"
            placeholder="#999999"
          />
        </ThemeSectionGroup>

        <ThemeSectionGroup title="News">
          <NumberInput
            id="news_header_height"
            name="news_header_height"
            value={infos.stylesheet.news.header_height}
            onChange={handleNewsHeaderHeightChange}
            label="Header Height (px)"
            description="Height of the news section header"
          />
          <ColorInput
            id="news_title_color"
            name="news_title_color"
            value={infos.stylesheet.news.title_color}
            onChange={handleNewsTitleColorChange}
            label="Title Color"
          />
          <ColorInput
            id="news_items_background_color"
            name="news_items_background_color"
            value={infos.stylesheet.news.items_background_color}
            onChange={handleNewsItemsBackgroundColorChange}
            label="Items Background Color"
            placeholder="#ffffff"
          />
          <ColorInput
            id="news_items_title_color"
            name="news_items_title_color"
            value={infos.stylesheet.news.items_title_color}
            onChange={handleNewsItemsTitleColorChange}
            label="Items Title Color"
          />
          <ColorInput
            id="news_items_subtitle_color"
            name="news_items_subtitle_color"
            value={infos.stylesheet.news.items_subtitle_color}
            onChange={handleNewsItemsSubtitleColorChange}
            label="Items Subtitle Color"
            placeholder="#666666"
          />
          <ColorInput
            id="news_items_date_color"
            name="news_items_date_color"
            value={infos.stylesheet.news.items_date_color}
            onChange={handleNewsItemsDateColorChange}
            label="Items Date Color"
            placeholder="#999999"
          />
          <NumberInput
            id="news_item_cell_height"
            name="news_item_cell_height"
            value={infos.stylesheet.news.cell_height}
            onChange={handleNewsCellHeightChange}
            min={60}
            label="Cell Height (px)"
            description="Minimum height for news items (min: 60px)"
          />
          <NumberInput
            id="news_padding"
            name="news_padding"
            value={infos.stylesheet.news.padding}
            onChange={handleNewsPaddingChange}
            label="Section Padding (px)"
            description="Padding around news items"
          />
          <NumberInput
            id="items_spacing"
            name="items_spacing"
            value={infos.stylesheet.news.items_spacing}
            onChange={handleNewsItemsSpacingChange}
            label="Items Spacing (px)"
            description="Space between news items"
          />
          <NumberInput
            id="news_details_header_height"
            name="news_details_header_height"
            value={infos.stylesheet.news.details_header_height}
            onChange={handleNewsDetailsHeaderHeightChange}
            label="Details Header Height (px)"
            description="Height of the header in details view"
          />
        </ThemeSectionGroup>

        <ThemeSectionGroup title="Pages">
          <NumberInput
            id="page_section_height"
            name="page_section_height"
            value={infos.stylesheet.pages.section_height}
            onChange={handlePageSectionHeightChange}
            label="Section Height (px)"
            description="Height of page sections"
          />
          <NumberInput
            id="pages_icon_height"
            name="pages_icon_height"
            value={infos.stylesheet.pages.icon_height}
            onChange={handlePagesIconHeightChange}
            label="Icon Height (px)"
            description="Height of page icons"
          />
          <NumberInput
            id="page_row_height"
            name="page_row_height"
            value={infos.stylesheet.pages.row_height}
            onChange={handlePageRowHeightChange}
            label="Row Height (px)"
            description="Height of page rows"
          />
          <ColorInput
            id="page_title_color"
            name="page_title_color"
            value={infos.stylesheet.pages.title_color}
            onChange={handlePageTitleColorChange}
            label="Title Color"
          />
          <ColorInput
            id="page_section_color"
            name="page_section_color"
            value={infos.stylesheet.pages.section_color}
            onChange={handlePageSectionColorChange}
            label="Section Color"
            placeholder="#666666"
          />
          <NumberInput
            id="pages_header_height"
            name="pages_header_height"
            value={infos.stylesheet.pages.header_height}
            onChange={handlePagesHeaderHeightChange}
            label="Header Height (px)"
            description="Height of page headers"
          />
        </ThemeSectionGroup>

        <ThemeSectionGroup title="Notifications">
          <NumberInput
            id="notifications_padding"
            name="notifications_padding"
            value={infos.stylesheet.notifications.padding}
            onChange={handleNotificationsPaddingChange}
            label="Padding (px)"
            description="Padding around notifications"
          />
          <NumberInput
            id="notifications_items_spacing"
            name="notifications_items_spacing"
            value={infos.stylesheet.notifications.items_spacing}
            onChange={handleNotificationsItemsSpacingChange}
            label="Items Spacing (px)"
            description="Horizontal spacing between items"
          />
          <NumberInput
            id="notifications_vertical_spacing"
            name="notifications_vertical_spacing"
            value={infos.stylesheet.notifications.items_vertical_spacing}
            onChange={handleNotificationsVerticalSpacingChange}
            label="Items Vertical Spacing (px)"
            description="Vertical spacing between items"
          />
        </ThemeSectionGroup>
      </ThemeSection>

      <ThemeSection title="Headers Images">
        <ImageEditorsGroup>
          <ImageEditor
            currentImage={infos.festival_logo || undefined}
            onChange={handleLogoChange}
            getApiImageUrl={getApiImageUrl}
            fillMode="contain"
            title="Festival Logo"
            caption="Will be displayed in the app header"
          />
          <ImageEditor
            currentImage={infos.news_header_image || undefined}
            onChange={handleNewsHeaderImageChange}
            getApiImageUrl={getApiImageUrl}
            fillMode="cover"
            title="News Header"
            caption="Displayed at the top of news screen"
          />
          <ImageEditor
            currentImage={infos.tickets?.image || undefined}
            onChange={handleTicketImageChange}
            getApiImageUrl={getApiImageUrl}
            fillMode="cover"
            title="Tickets Header"
            caption="Displayed at the top of tickets screen"
          />
        </ImageEditorsGroup>
      </ThemeSection>
    </section>
  );
};

export default InfosEditor;
